import React from 'react'
import 
{ BsGrid1X2Fill, BsFillArchiveFill, BsFillGrid3X3GapFill, BsPeopleFill, 
  BsListCheck}
 from 'react-icons/bs'
 import { CiPower } from "react-icons/ci";


import { useHistory } from "react-router-dom";
const Sidebar = ({openSidebarToggle, OpenSidebar}) => {
    const history = useHistory()
  
  
    const Logout=()=>{
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('session');
    localStorage.removeItem('Type');
    localStorage.removeItem('user');
    localStorage.removeItem("cartItems");
    history.push('/Login')
 
  }
  return (


    
      <aside id="sidebar" className={openSidebarToggle ? "sidebar-responsive": ""}>
          <div className='sidebar-title'>
              <div className='sidebar-brand'>
                  PROMOTOS
              </div>
              <span className='icon close_icon' onClick={OpenSidebar}>X</span>
          </div>
  
          <ul className='sidebar-list'>
              <li className='sidebar-list-item'>
                  <a href="/">
                      <BsGrid1X2Fill className='icon'/> Tableau de bord
                  </a>
              </li>
             
              <li className='sidebar-list-item'>
                  <a href="/Agence">
                      <BsFillGrid3X3GapFill className='icon'/> Liste des agences
                  </a>
              </li>
              <li className='sidebar-list-item'>
                  <a href="/Users">
                      <BsPeopleFill className='icon'/> Liste des agents
                  </a>
              </li>
              <li className='sidebar-list-item'>
                  <a href="/catalogue">
                      <BsListCheck className='icon'/> Liste des produits
                  </a>
              </li>
              <li className='sidebar-list-item'>
                  <a href="/Stock">
                      <BsFillArchiveFill className='icon'/>Suivi stock
                  </a>
              </li>
              <li className='sidebar-list-item'>
                  <a href=""
                   onClick={Logout}>
                      <CiPower  className='icon'/> Déconnexion
                  </a>
              </li>
          </ul>
      </aside>
    )
  }


export default Sidebar;
