import React from 'react'
import { useHistory } from "react-router";
import "react-rater/lib/react-rater.css";
import Dashboard from "./Dashbord"
import Home from './Home';




const PageHome = () => {
  const role = localStorage.getItem('Type');

    
   
    const history = useHistory();

    return (
        <div>


             {role === "user" ? (
                <Home/>
            ) : role === "admin" ? (
                <Dashboard />
            ) : (
                history.push('/Login')
            )




            } 

        </div>
    );
};

export default PageHome;
