import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://api.scooter-promotos.tn:8089/api',
  headers: {
    'Content-Type': 'application/json',
  },
});
const refreshAccessToken = async () => {
 
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      throw new Error('No refresh token available');
    }

    const response = await axiosInstance.post('/refresh-token', {
      refreshToken,
    });
  
    const { accessToken, newRefreshToken } = response.data;

    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', newRefreshToken);

    return accessToken;
  } catch (error) {
    console.error('Failed to refresh access token:', error);
    // Handle logout or redirect to login page
    throw error; // Optionally handle this in your components
  }
};
// Function to handle token refresh
const refreshToken = async () => {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    const response = await axiosInstance.post('/refresh-token', { token: refreshToken });
    const { accessToken } = response.data;
    localStorage.setItem('token', accessToken);
    return accessToken;
  } catch (error) {
    console.log('Error refreshing token:', error);
    // Handle token refresh failure, e.g., redirect to login page
    return null;
  }
};

// Request interceptor to add Authorization header with access token
axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('accessToken');
    
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle token expiration
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // If error response is due to token expiration and there's no previous retry
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const accessToken = await refreshAccessToken()

      if (accessToken) {
        originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
        return axiosInstance(originalRequest); // Retry the original request
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
