import React from "react";
import { useEffect, useRef, useState } from "react";
import {  useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import 'react-phone-number-input/style.css'
import { network } from "../constants";
import {Typeahead} from 'react-bootstrap-typeahead'
import {VilleOptions,ClientOptions} from '../data.js'
import { Col, Row, Button, Form } from "react-bootstrap";

import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom';

import axiosInstance from './api';


const DetailCommande = ({ cmd, onPress }) => {
  
  const role = localStorage.getItem('Type');
  const {agences } = useSelector((state) => state.agences);
  const [agents, setagents] = useState([])
  const location = useLocation();
  
 
  const [nature, setnature] = useState([])
  const [Numero,setNumero]=useState([])

  const [agent, setAgent] = useState([]);
  const [agence, setAgence] = useState([]);
  const [Allagent, setAllagent] = useState([]);
  const [Allproducts,setAllproducts]=useState([]);
  const [nom, setnom] = useState("");
  const [ville, setVille] = useState([]);
  const [adresse, setadresse] = useState("");
  const [cin, setcin] = useState("");
  const [Tel, setTel] = useState("");
  const [Titre, setTitre] = useState('C.I.N');
  
  

  const Refnom = useRef()
  const Refcin = useRef()

  const RefTel = useRef()
  const RefAdr = useRef()
 
  const changernature=(item)=>{
    if (item.length >0)
      {
        const nat = item [0]['label']
        setTitre(nat=='Particulier'?'C.I.N':'MF')
      }
  
  }
  
  const listeModeles = (item) => {
    if (item.length > 0) {
      const agence2 = item[0]['label']
    
      const data = agents.filter(
         row => (row.Agence === agence2))
       setAllagent(data)
       setAgent([])
      
     } else {
      setAllagent(agents)
     }
  }

  useEffect(() => {
    axiosInstance.get(`/commandes/${cmd.Nf}`)
    
      .then((res) => {
     
        setNumero(cmd.Nf)
        setnom(res.data.LibTiers)
        setcin(res.data.AssujTiers)
       
        setTel(res.data.Remarq1)
        setadresse(res.data.Adresse)
        setVille(VilleOptions.filter(x => x.label === res.data.Ville))
        setnature(ClientOptions.filter(x => x.label === res.data.Cin))
        listeModeles(agences.filter(x=>x.label ===res.data.Agence))
        setAgence(agences.filter(x=>x.label ===res.data.Agence))
        setAgent(agents.filter(x=>x.label ===res.data.Remarq0))
        


      });
  }, [agents])

  useEffect(() => {
    axiosInstance.get(`/LigCmd/${cmd.Nf}`)
      
      .then((res) => {
       
        setAllproducts(res.data)
        // localStorage.setItem("cartItems", JSON.stringify(res));


      });
  }, [location.state])

  useEffect(() => {

    axiosInstance.get(`/Tiers`)
    
     
      .then((res) => {
    
        setagents(res.data)


      });


  },[])


  const Valider= () =>{
  


    const MySwal = withReactContent(Swal)
    MySwal.fire({
      title: 'Confirmation',
      text: `voulez-vous valider votre commande ?`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non'
    }).then((res) => {
      if (res.isConfirmed) {
        axiosInstance.put(`/commandes/valid/${cmd.Nf}`)
          .then((res) => {
            MySwal.fire(
              'Validation avec succès   !',
              'validation .',
              'success'
            ).then((res) => {
              if (res.isConfirmed) {
                
                window.location.reload()
              }
            })
          })
          .catch((error) => {
            const MySwal = withReactContent(Swal)

            MySwal.fire({
              title: 'error?',
              text: ` Error de Serveur `,
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'ok'
            })


          });
        
      }
    })
  }
  const Confirmer= () =>{
  


    const MySwal = withReactContent(Swal)
    MySwal.fire({
      title: 'Confirmation',
      text: `voulez-vous confirmer votre commande ?`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non'
    }).then((res) => {
      if (res.isConfirmed) {
        axiosInstance.put(`/commandes/confirmer/${cmd.Nf}`)
            
          .then((res) => {
            MySwal.fire(
              'Confirmation avec succès   !',
              'Confirmation .',
              'success'
            ).then((res) => {
              if (res.isConfirmed) {
                
                window.location.reload()
              }
            })
          })
          .catch((error) => {
            const MySwal = withReactContent(Swal)

            MySwal.fire({
              title: 'error?',
              text: ` Error de Serveur `,
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'ok'
            })


          });
        
      }
    })
  }




  
  const handleSubmit = (e) => {
    e.preventDefault();
 
    const order = {
      // products: cart,
      Matricule : agent [0]['Matricule'],
      Nf:Numero,
      agent: agent [0]['label'],
      agence: agence [0]['label'],
      nature : nature [0]['label'],
      nom: nom,
      cin: cin,
      adresse: adresse,
      Tel: Tel,     
      ville: ville [0]['label'],
      

    }
  
    const MySwal = withReactContent(Swal)
    MySwal.fire({
      title: 'Confirmation',
      text: `voulez-vous modifier votre commande ?`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non'
    }).then((res) => {
      if (res.isConfirmed) {
        axiosInstance.put(`/Ligcmd/Update`, order)
    
          .then((res) => {
            MySwal.fire(
              'Modification avec succès   !',
              'Modification .',
              'success'
            ).then((res) => {
              if (res.isConfirmed) {
                
                window.location.reload()
              }
            })
          })
          .catch((error) => {
            const MySwal = withReactContent(Swal)

            MySwal.fire({
              title: 'error?',
              text: ` Error de Serveur `,
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'ok'
            })


          });
     
      }
    })



  }
  return (
    <>

 
      <div>





      <h5 className="text-danger my-3"> Votre commande est {cmd.status==0? "non valide" : "valide" }</h5>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            //  height: "70vh",
            justifyContent: "center",
          }}
        >
          <div
            className="client"
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              position: "relative",
              marginBottom: "5%",
              marginTop: "3%",
            }}
          >
            
          

            
           
             
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Group id="lastName">
                    <Form.Label>Agence<span className="text-danger fw-bold">*</span></Form.Label>


                  <Typeahead
                  id="my-typeahead-id"
                  labelKey="label"
                  defaultValue={agence}
                  onChange={(item)=>{setAgence(item);listeModeles(item)}}
                  options={agences}
                  placeholder="----"
                  selected={agence}
                  disabled={role=='admin'? false :true}
                  clearButton
                  />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group id="lastName">
                    <Form.Label>Agent<span className="text-danger fw-bold">*</span></Form.Label>


                    
                    <Typeahead
                    id="my-typeahead-id"
                    labelKey="label"
                    disabled={role=='admin'? false :true}
                  defaultValue={agent}
                  onChange={(item)=>setAgent(item)}
                  options={Allagent}
                  placeholder="----"
                  selected={agent}
                  clearButton
                  />

                
                  </Form.Group>
                </Col>


              </Row>
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Group id="lastName">
                    <Form.Label>Nature Client<span className="text-danger fw-bold">*</span></Form.Label>


                    <Typeahead
                    id="my-typeahead-id"
                    labelKey="label"
                    // disabled
                  defaultValue={nature}
                  onChange={(item)=>{setnature(item);changernature(item)}}
                  options={ClientOptions}
                  placeholder="----"
                  selected={nature}
                  clearButton
                  />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group >
                    <Form.Label>{Titre}<span className="text-danger fw-bold">*</span></Form.Label>
                    <Form.Control
                    // disabled
                      required
                      type="tel"
                      maxLength="8"
                      value={cin}
                      ref={Refcin}
                      onChange={(e) => setcin(e.target.value)}
                      // onKeyDown={(e) => {

                      //   if (e.keyCode === 13) {
                      //     RefEmail.current.focus()
                      //   }
                      // }}

                      className="inputStyle2 input-color border border-dark"

                      placeholder="Entrer C.I.N / M.F"
                    />
                  </Form.Group>
                </Col>

              </Row>

            


              <Row className="my-2">
                <Col md={6}>
                  <Form.Group >
                    <Form.Label>Raison sociale<span className="text-danger fw-bold">*</span></Form.Label>
                    <Form.Control
                      // disabled
                      required
                      ref={Refnom}
                      value={nom}
                      onChange={(e) => setnom(e.target.value)}
                      onKeyDown={(e) => {

                        if (e.keyCode === 13) {
                          RefTel.current.focus()
                        }
                      }}
                      name="prenom"
                      className="inputStyle input-color border border-dark"
                      type="text"
                      placeholder="Entrer Nom & Prénom / Société"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group >
                    <Form.Label>Téléphone<span className="text-danger fw-bold">*</span></Form.Label>
                    <Form.Control
                      // disabled
                      required
                     
                      value={Tel}
                      // onChange={(e) => setnom(e.target.value)}
                      
                      name="prenom"
                      className="inputStyle input-color border border-dark"
                      type="text"
                   
                    />
                    

                  </Form.Group>
                </Col>

              </Row>







              <Row className="my-2">
              <Col md={6}>
                  <Form.Group >
                    <Form.Label>Adresse<span className="text-danger fw-bold">*</span></Form.Label>
                    <Form.Control
                    // disabled
                      required
                      ref={RefAdr}
                      onChange={(e) => setadresse(e.target.value)}

                      value={adresse}

                      className="inputStyle3 input-color border border-dark"
                      type="text"
                      placeholder="Entrer Adresse"
                    />
                  </Form.Group>
                </Col>
               
                <Col md={6}>
                  <Form.Group >
                    <Form.Label>Ville<span className="text-danger fw-bold">*</span></Form.Label>
                    <Typeahead
                    id="my-typeahead-id"
                    // disabled
                  defaultValue={ville}
                  onChange={(item)=>{setVille(item)}}
                  options={VilleOptions}
                  placeholder="----"
                  selected={ville}
                  />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="my-2">

                {/* <Col>
                      <Form.Group >
                        <Form.Label>Code postal</Form.Label>
                         <Form.Control
                           required
                          type="tel"
                          maxLength="4"
                          ref={Refcp}
                          onChange={(e) => setcp(e.target.value)}
                         value={cp}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                               RefAdr.current.focus()
                             }
                           }}
                           name="telephone"
                          className="inputStyle2 input-color border border-dark"

                           placeholder="Entrer code Postal"
                         />
                       </Form.Group>
                     </Col> */}
              </Row>
              
              
             <div className="cart-item">
            <h5 className="product-title">Produit</h5>
            <h5 className="price">Prix.TTC</h5>
            <h5 className="quantity">Quantité</h5>
            <h5 className="total">Total.TTC</h5>
          </div>
              <div className="cart-items">
                {Allproducts&&
                  Allproducts.map((cartItem) => (
                    <div className="cart-item" key={cartItem.IDArt}>
                      <div className="cart-product">
                        <img src={cartItem.ExLibArt} alt={cartItem.name} />
                        <div>
                          <h5>{cartItem.LibArt}</h5>
                          <p>{cartItem.desc}</p>
                          {/* <5utton onClick={() => handleRemoveFromCart(cartItem)}>
                            Supprimer
                          </button> */}
                        </div>
                      </div>
                      <div className="cart-product-price">{cartItem.PuTTC.toFixed(3)}</div> 
                      <div className="cart-product-quantity2">
                       
                        <div >{cartItem.cartQuantity}</div>
                        {/* <button onClick={() => handleAddToCart(cartItem)}>+</button> */}
                      </div>
                      
                      <div className="cart-product-total-price">
                    {(cartItem.PuTTC * cartItem.cartQuantity).toFixed(3)}
                  </div>
                      
                    </div>
                  ))}
              </div>
              
              <Row>
                <Col></Col>
                <Col align='right'>
                <Button className="me-3" variant="primary" type="submit"
                onClick={handleSubmit}
                >

                Enregistrer
              </Button>

              {cmd.status==0 &&
              <Button className="me-3" variant="success"
              onClick={()=>Valider()}
              >

                Valider
              </Button>
             }
              {cmd.status==1 && role=="admin" &&
              <Button className="me-3" variant="warning"
              onClick={()=>Confirmer()}
              >

                Confirmer
              </Button>
             }
              <Button className="me-3" variant='danger'
               onClick={() => onPress()} >

               Fermer
              </Button>
                </Col>
              
                
            
              
              </Row>
         
          </div>

        </div>


      </div>









      {/* 
        {/* <div className="invoice__preview bg-white p-5 rounded-2xl border-4 border-blue-200">
          <ReactToPrint
            trigger={() => (
              <button className="bg-blue-500 ml-5 text-white font-bold py-2 px-8 rounded hover:bg-blue-600 hover:text-white transition-all duration-150 hover:ring-4 hover:ring-blue-400">
                Print / Download
              </button>
            )}
            content={() => componentRef.current}
          />

          <div ref={componentRef} className="p-5"  >
             
            <section className="flex flex-col items-end justify-end">
              <h2 className="font-bold text-3xl uppercase mb-1">PROMOTOS</h2>
              <p>Route Gremda km 4.5</p>
            </section>
            <Row className="mb-5">
              <Col>
                <div className="flex flex-col">
                  <h2 className="font-bold uppercase tracking-wide text-4xl mb-3">
                    Devis N° 2400004
                  </h2>
                  <p className="font-bold uppercase tracking-wide text-4xl mb-3">
                    Date : 01/12/2024
                  </p>
                </div>
              </Col>
              {/* <MainDetails  */}














    </>

  );
};

export default DetailCommande;


