 import "./App.css";
import { BrowserRouter, Route, Switch, Redirect,Navigate } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Home from "./components/Home";

import NotFound from "./components/NotFound";
import Cart from "./components/Cart";
import Login  from "./components/Login";
// import "react-toastify/dist/ReactToastify.css";
import Commande from "./components/Commande";
import Profile from "./components/Profile";
import ListAgent from "./components/ListAgent";


import Footer from "./components/Footer";
import ListAgence from "./components/ListAgence";
import ListArticles from "./components/ListArticles";

import Product from "./components/Product";
import ListCommande from "./components/ListCommande";


import PageHome from "./components/PageHome";


function App() {


 
  return (
   
    
        
   
    <div >
      <BrowserRouter>
        {/* <ToastContainer /> */}
        
        
       
          <Switch>
            
          <PrivateRoute path="/cart" component={Cart} />
          <PrivateRoute path="/profile" component={Profile} />
          <PrivateRoute path="/commandes" component={Commande} />
          <PrivateRoute path="/commande" component={ListCommande} />
          <PrivateRoute path="/stock" component={ListArticles} />
          <PublicRoute path="/login" component={Login} />
          <PrivateRoute path="/users" component={ListAgent} />
          <PrivateRoute path="/agence" component={ListAgence} />
          <PrivateRoute path="/products" component={Product} />
          <PrivateRoute path="/catalogue" component={Home} />
          <PrivateRoute path="/" exact component={PageHome} />
          <Route component={NotFound} />
         
          </Switch>
          <Footer/>
          
         
      </BrowserRouter>
      
    </div>
  );
}

export default App;
