import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import ReactImageGallery from "react-image-gallery";
import { Col, Row } from 'react-bootstrap';
import ScrollToTop from "react-scroll-to-top";
import "react-rater/lib/react-rater.css";
import axiosInstance from './api';
import Carousel from 'react-bootstrap/Carousel';
import ReactPlayer from 'react-player'
import { addToCart, getTotals } from "../slices/cartSlice";

import Sidebar from './Sidebar';
import Header from './Header';



const Home = () => {
    
    const dispatch = useDispatch();
    const history = useHistory();
   
    const cart = useSelector((state) => state.cart);
   
    const [items,setitems] = useState([])

    const [Update, SetUpdate] = useState("Green")
    const [Update2, SetUpdate2] = useState("Green")
    const [code, Setcode] = useState("MOTAN009")
    // const [Qt, setQt] = useState(0)
    const [product, setproduct] = useState([])
    const [product2, setproduct2] = useState([])
   
   
    useEffect(()=>{
        axiosInstance.get(`/articles`)
        .then((res)=>{
          setitems(res.data)
        //   setAllproducts(res.data)
        })
        
        
       },[])
   
   
    useEffect(() => {

        const filteredItems2 = items.filter(row => row.DesSFam === 'LX08');
        const filteredItems = items.filter(row => row.DesSFam === 'LX04');

        if (filteredItems.length > 0) {
            SetUpdate(filteredItems[0].Collection)

        }
        if (filteredItems2.length > 0) {
            SetUpdate2(filteredItems2[0].Collection)
        }
        dispatch(getTotals());
       
    }, [items])

    const handleAddToCart = (product) => {
        // console.log(product)
        dispatch(addToCart(product))
        history.push('/cart')
        //    SetUpdate(true)
    };
    const productDetailItem = {
        images: [
            {
                original: `https://scooter-promotos.tn/photos/LX08/${Update2}/LX08-promotos-scooter-electrique.jpg`,
                thumbnail: `https://scooter-promotos.tn/photos/LX08/${Update2}/LX08-promotos-scooter-electrique.jpg`,
            },


            {
                original: `https://scooter-promotos.tn/photos/LX08/${Update2}/LX08-Promotos-scooter-view.jpg`,
                thumbnail: `https://scooter-promotos.tn/photos/LX08/${Update2}/LX08-Promotos-scooter-view.jpg`,
            },
            {
                original:  `https://scooter-promotos.tn/photos/LX08/Promotos-LX08-ecran-lcd.jpg`,
                thumbnail:  `https://scooter-promotos.tn/photos/LX08/Promotos-LX08-ecran-lcd.jpg`,
            },
            {
                original:  `https://scooter-promotos.tn/photos/LX08/Promotos-LX08-tableau-de bord-3-vitesses.jpg`,
                thumbnail:  `https://scooter-promotos.tn/photos/LX08/Promotos-LX08-tableau-de bord-3-vitesses.jpg`,
            },
            // {
            //     original: require(`../assets/LX08/Promotos-LX08-USB.png`).default,
            //     thumbnail: require("../assets/LX08/Promotos-LX08-USB.png").default,
            // },
            {
                original: `https://scooter-promotos.tn/photos/LX08/Promotos-LX08-frein-a-disque-avant.jpg`,
                thumbnail:  `https://scooter-promotos.tn/photos/LX08/Promotos-LX08-frein-a-disque-avant.jpg`,
            },
            {
                original: `https://scooter-promotos.tn/photos/LX08/Promotos-LX08-batterie-lithium.jpg`,
                thumbnail:  `https://scooter-promotos.tn/photos/LX08/Promotos-LX08-batterie-lithium.jpg`,
            },
            {
                original:  `https://scooter-promotos.tn/photos/LX08/Promotos-LX08-frein-a-disque-arriere.jpg`,
                thumbnail:  `https://scooter-promotos.tn/photos/LX08/Promotos-LX08-frein-a-disque-arriere.jpg`,
            },

           
        ],
        images2: [
            {
                original:   `https://scooter-promotos.tn/photos/LX04/${Update}/Promotos-LX04-front-view.jpg`,
                thumbnail:  `https://scooter-promotos.tn/photos/LX04/${Update}/Promotos-LX04-front-view.jpg`,
            },
            {
                original: `https://scooter-promotos.tn/photos/LX04/${Update}/Promotos-LX04-rear-view.jpg`,
                thumbnail: `https://scooter-promotos.tn/photos/LX04/${Update}/Promotos-LX04-rear-view.jpg`,
            },
            {
                original:  `https://scooter-promotos.tn/photos/LX04/Promotos-LX04-batterie.jpg`,
                thumbnail:  `https://scooter-promotos.tn/photos/LX04/Promotos-LX04-batterie.jpg`,
            },
            //   {
            //     original:require(`../assets/LX04/${Update}/Promotos-LX04-batterie-lithium.png`).default,
            //     thumbnail:require("../assets/LX04/${Update}/Promotos-LX04-batterie-lithium.png").default,
            //   },
            {
                original:  `https://scooter-promotos.tn/photos/LX04/Promotos-LX04-frein-a-disque-arriere.jpg`,
                thumbnail: `https://scooter-promotos.tn/photos/LX04/Promotos-LX04-frein-a-disque-arriere.jpg`,
            },
            {
                original:  `https://scooter-promotos.tn/photos/LX04/Promotos-LX04-frein-a-disque-avant.jpg`,
                thumbnail:  `https://scooter-promotos.tn/photos/LX04/Promotos-LX04-frein-a-disque-avant.jpg`,
            },
            {
                original:  `https://scooter-promotos.tn/photos/LX04/Promotos-LX04-coonecteur.jpg`,
                thumbnail:  `https://scooter-promotos.tn/photos/LX04/Promotos-LX04-coonecteur.jpg`,
            },
            {
                original: `https://scooter-promotos.tn/photos/LX04/Promotos-LX04-ecran-LCD.jpg`,
                thumbnail:  `https://scooter-promotos.tn/photos/LX04/Promotos-LX04-ecran-LCD.jpg`,
            },


        ],

    };

    const role = localStorage.getItem('Type');

    const [openSidebarToggle, setOpenSidebarToggle] = useState(false)

    const OpenSidebar = () => {
      setOpenSidebarToggle(!openSidebarToggle)
    }
    return (
        <div className={role==='admin' ?'grid-container':''}>
        <Header OpenSidebar={OpenSidebar}/>
  {role==='admin' &&
<Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar}/>

  }

 
  <main className='main-container'>
                <ScrollToTop className='mb-4' smooth viewBox="0 0 24 22" svgPath="M18 15l-6-6-6 6" />
                <Carousel data-bs-theme="dark">
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="https://www.lvnengebike.com/uploadfiles/128.1.164.27/webid822/banner/202207/62d1027a25064.jpg"

                            //    style={{resizeMode: 'stretch',height : "900px"}} 
                            alt="First slide"
                        />
                        <Carousel.Caption>


                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="https://www.lvnengebike.com/pcuploadfiles/128.1.164.27/webid822/MOD/202008/1598260968868.jpeg"
                            alt="Second slide"
                        />
                        <Carousel.Caption>


                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src= {require(`../assets/pikaso_edit.jpg`).default}
                            alt="Third slide"
                        />
                        <Carousel.Caption>


                        </Carousel.Caption>
                    </Carousel.Item>
                    {/* <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src= {require(`../assets/pikaso2.jpg`).default}
                            alt="Third slide"
                        />
                        <Carousel.Caption>


                        </Carousel.Caption>
                    </Carousel.Item> */}
                </Carousel>



                <div className="container my-5">

                    {/* image gallery */}
                    <Row>
                        <Col>
                            <div className="container mx-auto px-4">
                                <ReactImageGallery
                                    showBullets={false}
                                    showFullscreenButton={false}
                                    showPlayButton={false}
                                    items={productDetailItem.images2}
                                />


                            </div>
                        </Col>
                        <Col>
                            <div
                            // className="store-wrapper home-wrapper-2 py-5 mainPart"
                            // style={{ height: "20vh" }}
                            >
                                <div className="container-fluid bg-light p-5">
                                    <div className="row justify-content-around ">
                                        <div className="col-11 prod-card d-flex align-items-center ">


                                            <div
                                                className="detail-box"
                                                style={{
                                                    marginLeft: "5%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <h2
                                                    style={{
                                                        color: "black",
                                                        fontWeight: "600",
                                                        margin: "0",
                                                        marginBottom: "15px",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    SCOOTER ÉLECTRIQUE LVNENG LX04 - {Update.toUpperCase()}
                                                </h2>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        height: "100%",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            //   marginLeft: "5%",
                                                            marginTop: "1%",
                                                            //   width: "60%",
                                                            height: "100%",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent: "space-around",
                                                        }}
                                                    >

                                                        <div>
                                                            <h4 className="firstIn">Description</h4>
                                                            <p style={{ fontSize: "16px", textAlign: "justify" }}>
                                                                Scooter Électrique LVNENG LX04 - Cylindré: 49cc - Type
                                                                de Moteur: Bosch - Puissance de Moteur: 1440 Watts -
                                                                Vitesse max: 45km/h - Batterie au Lithium 48V26Ah
                                                                (portable) - Temps de recharge: 4 heures (de 0 à 100%) -
                                                                Autonomie de Batterie: 50 Km - Frein à disque avant et
                                                                arrière - Pneus tubeless avant et arrière 80/100-10".
                                                            </p>
                                                        </div>
                                                        <div>
                                                            {/* <h4 className="firstIn">Stock: </h4> */}
                                                            {/* <p><strong>{ Qt} </strong></p>   */}
                                                        </div>
                                                        <div>
                                                            <h4 className="firstIn">Couleur  </h4>

                                                        </div>
                                                        <div style={{ display: "flex", flexDirection: "row" }}>

                                                            <div className="product-tags d-flex flex-wrap align-items-center gap-10">
                                                                {items && items.filter(row => row.DesSFam == 'LX04').map((row) => (
                                                                    <button className='shadow-sm' style={{
                                                                        display: "inline-block",
                                                                        width: "30px", /* Adjust the width and height to your preference */
                                                                        height: "30px",
                                                                        borderRadius: "50%", /* Makes the span appear as a circle */
                                                                        margin: "5px",
                                                                         border: "0",
                                                                         boxShadow: "rgba(0, 0, 0, 0.24)",
                                                                         borderColor:row.Couleur,
                                                                      
                                                                        

                                                                        backgroundColor: row.Couleur
                                                                    }}
                                                                        // onLoadStart={()=>SetUpdate2(row.Collection)}
                                                                        onClick={() => { SetUpdate(row.Collection); setproduct(row) }}></button>
                                                                ))}


                                                            </div>{" "}
                                                        </div>
                                                        {cart.cartItems.length === 0 &&
                                                            <button className='btn btn-primary me-3'
                                                                hidden={product.length == 0 ? true : false}
                                                                style={{
                                                                    marginTop: "15px",
                                                                    backgroundColor: "#1a6985",
                                                                    padding: "5px",
                                                                    border: "0",
                                                                    borderRadius: "35px",
                                                                    color: "white",
                                                                    width: "200px"
                                                                }}
                                                                onClick={() => handleAddToCart(product)}

                                                            >
                                                                <i className='bi bi-cart-plus fs-5 me-3'></i>
                                                                Ajouter au panier
                                                            </button>
                                                        }
                                                        <img
                                                            src={require("../assets/zitouna.PNG").default}
                                                            alt="zitouna"
                                                            style={{
                                                                display: "block",
                                                                borderRadius: "10px",
                                                                marginTop:"20px"
                                                            }}
                                                        />
                                                    </div>
                                                    <div

                                                    >



                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>


                    </Row>


                    <Row className='justify-content-center my-5'>

                        <ReactPlayer url='https://scooter-promotos.tn/photos/LX04.mp4'
                         width='100%'
                         height='100%'
                         controls = {true}
                           
                        />

                    </Row>
                    <Row className='justify-content-center my-5'>
                        {/* <img
//   className="d-block w-100"
src={require(`../assets/catalogue/catalogue_Promotos_Scooters_électriques_V4_page-0002.jpg`).default}
alt="Third slide"
/>
<img
//   className="d-block w-100"
src={require(`../assets/catalogue/catalogue_Promotos_Scooters_électriques_V4_page-0003.jpg`).default}
alt="Third slide"
/> */}
                        {/* <img
//   className="d-block w-100"
src={require(`../assets/catalogue/catalogue_Promotos_Scooters_électriques_V4_page-0004.jpg`).default}
alt="Third slide"
/> */}
                        <img
                            //   className="d-block w-100"
                            src={require(`../assets/catalogue/catalogue_Promotos_Scooters_électriques_V4_page-0005.jpg`).default}
                            alt="Third slide"
                        />
                    </Row>
                    <Row>
                        <Col>
                            <div className="container mx-auto px-4">
                                <ReactImageGallery
                                    showBullets={false}
                                    showFullscreenButton={false}
                                    showPlayButton={false}
                                    items={productDetailItem.images}
                                />


                            </div>
                        </Col>
                        <Col>
                            <div
                            // className="store-wrapper home-wrapper-2 py-5 mainPart"
                            // style={{ height: "20vh" }}
                            >
                                <div className="container-fluid bg-light p-5">
                                    <div className="row justify-content-around ">
                                        <div className="col-11 prod-card d-flex align-items-center ">
                                            <div style={{ display: "flex", flexDirection: "row" }}>


                                            </div>

                                            <div
                                                className="detail-box"
                                                style={{
                                                    marginLeft: "5%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <h2
                                                    style={{
                                                        color: "black",
                                                        fontWeight: "600",
                                                        margin: "0",
                                                        marginBottom: "15px",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    SCOOTER ÉLECTRIQUE LVNENG LX08 - {Update2.toUpperCase()}
                                                </h2>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        height: "100%",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            //   marginLeft: "5%",
                                                            marginTop: "1%",
                                                            //   width: "60%",
                                                            height: "100%",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent: "space-around",
                                                        }}
                                                    >

                                                        <div>
                                                            <h4 className="firstIn">Description</h4>
                                                            <p style={{ fontSize: "16px", textAlign: "justify" }}>
                                                                Scooter Électrique LVNENG LX08 - Cylindré: 49cc - Type
                                                                de Moteur: Bosch - Puissance de Moteur: 2030 Watts -
                                                                Vitesse max: 60km/h - Batterie au Lithium 60V23.4Ah
                                                                (portable) - Temps de recharge: 4 heures (de 0 à 100%) -
                                                                Autonomie de Batterie: 50 Km - Frein à disque avant et
                                                                arrière - Pneus tubeless avant et arrière 80/100-10".
                                                            </p>
                                                        </div>
                                                        <div>

                                                            {/* <p><strong>{ product[0].Qt} </strong></p> */}
                                                        </div>
                                                        <div>
                                                            <h4 className="firstIn">Couleur  </h4>
                                                            <div className="product-tags d-flex flex-wrap align-items-center gap-10">
                                                                {items && items.filter(row => row.DesSFam == 'LX08').map((row) => (
                                                                    <button className='shadow-lg' style={{
                                                                        display: "inline-block",
                                                                        width: "30px", /* Adjust the width and height to your preference */
                                                                        height: "30px",
                                                                        borderRadius: "50%", /* Makes the span appear as a circle */
                                                                        margin: "5px",
                                                                        border: 0,
                                                                        zIndex : "auto",
                                                                        backgroundColor: row.Couleur
                                                                    }}
                                                                        // onLoadStart={()=>SetUpdate2(row.Collection)}
                                                                        onClick={() => { SetUpdate2(row.Collection); Setcode(row.code); setproduct2(row) }}></button>
                                                                ))}


                                                            </div>{" "}
                                                        </div>
                                                        <div style={{ display: "flex", flexDirection: "row" }}>


                                                        </div>
                                                        {cart.cartItems.length === 0 &&
                                                            <button
                                                                hidden={product2.length == 0 ? true : false}
                                                                style={{
                                                                    marginTop: "15px",
                                                                    backgroundColor: "#1a6985",
                                                                    padding: "5px",
                                                                    border: "0",
                                                                    borderRadius: "35px",
                                                                    color: "white",
                                                                    width: "200px",
                                                                    
                                                                }}
                                                                onClick={() => handleAddToCart(product2)}

                                                            >
                                                                <i className='bi bi-cart-plus fs-5 me-3'></i>
                                                                Ajouter au panier
                                                            </button>
                                                        }
                                                        <img
                                                            src={require("../assets/zitouna.PNG").default}
                                                            alt="zitouna"
                                                            style={{
                                                                display: "block",
                                                                borderRadius: "10px",
                                                                marginTop:"20px"
                                                            }}
                                                        />
                                                    </div>
                                                    <div

                                                    >



                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>


                    </Row>

                    <Row className='justify-content-center my-5'>

                       
                           <ReactPlayer url='https://scooter-promotos.tn/photos/LX08.mp4'
                           width='100%'
                           height='100%'
                           controls = {true}
                             
                          />
                        

                    </Row>

                    <Row className='justify-content-center my-5'>

                        <img
                            //   className="d-block w-100"
                            src={require(`../assets/catalogue/catalogue_Promotos_Scooters_électriques_V4_page-0007.jpg`).default}
                            alt="Third slide"
                        />
                        <img
                            //   className="d-block w-100"
                            src={require(`../assets/catalogue/catalogue_Promotos_Scooters_électriques_V4_page-0008.jpg`).default}
                            alt="Third slide"
                        />
                        <img
                            //   className="d-block w-100"
                            src={require(`../assets/catalogue/catalogue_Promotos_Scooters_électriques_V4_page-0009.jpg`).default}
                            alt="Third slide"
                        />

                    </Row>
                </div>
            
        </main>
        </div>
    );
};

export default Home;
