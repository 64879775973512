import React from 'react';
import Sidebar from "./Sidebar";
import { useState } from 'react'

import Header from './Header'


import NavBar from './NavBar';
import Commande from './Commande';

const Dashbord = () => {

  const [openSidebarToggle, setOpenSidebarToggle] = useState(false)

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle)
  }
  return (
    <div className='grid-container'>
      <Header OpenSidebar={OpenSidebar}/>
      <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar}/>
      <Commande/>
      
    </div>
  
    
  );
}

export default Dashbord;
