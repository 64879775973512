
import React, { useEffect,useState} from 'react';
import { useSelector,useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import NavBar from "./NavBar";
import { Col, Row } from '@themesberg/react-bootstrap';
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import { useHistory } from "react-router";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { addToCart,getTotals } from "../slices/cartSlice";
import ReactPlayer from 'react-player'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';
const Product = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const {items } = useSelector((state) => state.products);
    console.log(items)
    const [Allarticle,setAllarticle]=useState([])
    const [Update,SetUpdate]=useState(false)
    useEffect(()=>{
        const data =  items.filter( row => row.DesSFam=="LX08")
        setAllarticle(data)
    },[location,items])
    const { SearchBar } = Search;

    useEffect(() => {
      dispatch(getTotals());
    }, [ Update]);

    const handleAddToCart = (product) => {
      console.log(product)
      dispatch(addToCart(product))
     SetUpdate(true)
    };
  const columns = [
    {
      dataField:'ExLibArt',
      text  : 'Action',
      editable: false,
      formatter: (cellContent, row) => {
        return (
          <div>
            <Col>
            <img src={row.ExLibArt}
            style={{width:"100px"}} alt="..." />
            </Col>
         
            </div>
        )
      }
     },
    {
        dataField: 'CodArt',
        text: 'Code',
     
      },
      {
        dataField: 'LibArt',
        text: 'désignation',
      },
      
   {
    dataField: 'Qt',
    text: 'Stock',
    style:{color:'green'},
    // filter: textFilter(),
    

  },
      {
        dataField: 'LibFam',
        text: 'Famille',
     
      },
      {
        dataField: 'DesSFam',
        text: 'SFamille',
     
      },
 
  {
    dataField: 'Couleur',
    text: 'Couleur',
    // footer: 'Total :',
    align: 'center',
    style:{color:'red'},
    // filter: textFilter(),
    
  },
   {
    dataField: 'PrixTTC',
    text: 'PrixTTC',
    formatter: (cell) => (Number.parseFloat(cell).toFixed(3)),
    // filter: textFilter(),
   

   } ,
   
   {
    dataField:'act',
    text  : 'Action',
    editable: false,
    formatter: (cellContent, row) => {
      return (
        <div  className='d-flex justify-content-around'>
         <button title='Ajouter au Panier' className='btn btn-primary me-2' size="sm" 
         disabled={row.Qt <= 0}
         onClick={()=> handleAddToCart(row)}
        // onClick={()=>{setShow(true);setagent(row);setTitre('Modifier')}}
         >
         <AddShoppingCartIcon /> 
         </button>
         
          {/* <button title='Supprimer' className='btn btn-danger'  size="sm" 
        //    onClick={()=>Delete(row.Matricule)} 
          >
           <i className='bi bi-trash'></i>
           </button> */}
        </div>
      
      )
    },
   
  }
  
  // ,
  
  
  
  
  // {
  //   dataField:'act',
  //   text  : 'Action',
  //   editable: false,
  //   formatter: (cellContent, row) => {
  //     return (
  //       <div  className='d-flex justify-content-around'>
  //        <button title='Editer' className='btn btn-primary me-2' size="sm" 
        
  //       // onClick={()=>{setShow(true);setagent(row);setTitre('Modifier')}} 
  //       >
  //        <i className='bi bi-pencil'></i>
  //        </button>

  //         <button title='Supprimer' className='btn btn-danger'  size="sm" 
  //         //  onClick={()=>Delete(row.Matricule)} 
  //         >
  //          <i className='bi bi-trash'></i>
  //          </button>
  //       </div>
      
  //     )
  //   },
   
  // }
  





  ];

  return (
    <div>
      <NavBar /> 
      
       <div className="container">
       <Row className='text-center my-3'>
        <Col md={6} className='my-3'><h4 style={{ fontSize: '2.2em', textDecoration: 'underline' }}>Liste des produits disponible</h4></Col>
        </Row>
       <ToolkitProvider
        keyField="Matricule"
        data={Allarticle}
        columns={columns}
        search
        exportCSV={{
          onlyExportFiltered: true, exportAll: false,
          fileName: "Liste_Commandes.csv",
          blobType: 'text/plain;charset=utf-8 ',
          noAutoBOM: false,
          separator: ";"
        }}
      >
        {
          props => (<div className='mx-2 mb-4 '>
            <Row>
              <Col md={2}>
                <SearchBar {...props.searchProps} placeholder="rechercher ..." className='border-primary mx-3 mb-3 w-500' />
              </Col>
             
              {/* <Col md={2}>
                <ExportCSVButton variant="primary" {...props.csvProps} ><FontAwesomeIcon color='green' icon={faFileExcel} />  Exporter CSV </ExportCSVButton>
              </Col>*/}
            </Row> 
            <Row xs="auto" className='mx-3 mb-4 justify-content-start'>
              <Col>
                <BootstrapTable
                  striped
                  hover
                  pagination={paginationFactory()}
                  filter={filterFactory()}
                  {...props.baseProps}

                />
              </Col>
            </Row>
            
            <Row xs="auto" className='mx-3 mb-4 justify-content-start'>
              <Col>
              <ReactPlayer url='https://www.youtube.com/watch?v=N87FoRh1Sf8&ab_channel=%E7%BB%BF%E8%83%BD%E7%94%B5%E5%8A%A8%E8%BD%A6'
              
              width={500}
              style={{width:"50px"}} />
              </Col>
              <Col>
              <ReactPlayer url='https://www.youtube.com/watch?v=eiltUAGb6P4' 
              width={500}  />
              </Col>
        </Row>
          </div>
          )
        }

      </ToolkitProvider>
      
       </div>
       <div>
       
       </div>
       
       
    </div>
  );
}

export default Product;
