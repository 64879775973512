import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { network } from "../constants";
const initialState = {
 status: null,
  user : null,
  error:null
};
export const loginUser = createAsyncThunk(
    "user/loginUser",
    async (user) => {
      try {
        const request = await axios.post(`${network.serverip}/Login`,user);
       
        
        const response=await request.data;
       
        if(response.success===true)
        {
        localStorage.setItem('user',JSON.stringify(response))
       
        }
        return response
      } catch (error) {
        console.log(error);
      }
    }
  );
const userSlice= createSlice({
    name : 'user',
    initialState,
    reducers: {},
    extraReducers: {
        [loginUser.pending]: (state, action) => {
          state.status = "pending";
         

        },
        [loginUser.fulfilled]: (state, action) => {
          state.user = action.payload;
          state.status = "success";
        },
        [loginUser.rejected]: (state, action) => {
          state.status = "rejected";
        },
      },
})
export default userSlice.reducer;