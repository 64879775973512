import { useState } from "react";

import { useSelector } from "react-redux";

import Container from 'react-bootstrap/Container';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
// function getUser() {
//   let user = localStorage.getItem('user')
//   if (user) {
//     // console.log(user)
//     user = JSON.parse(user)
//     user = user.user.raisoc + ' ' + user.user.prenom
//   } else {
//     user = null;
//   }
//   return user;
// }
const NavBar = () => {
  const { cartTotalQuantity } = useSelector((state) => state.cart);
  // const user = JSON.parse(localStorage.getItem('user'))
  // const nom = user.user.raisoc + ' ' + user.user.prenom
  const nom ='test'
  const Logout = () => {
    localStorage.removeItem('user');

    window.location.href = '/Login';
    localStorage.removeItem("cartItems");

    // dispatch(setCurrentUser({}));
  }
  return (
    // <nav className="nav-bar">
    //   <Link to="/">
    //     <h2>PROMOTOS</h2>
    //   </Link>

    //   <Dropdown>
    //   <Dropdown.Toggle variant="success" id="dropdown-basic">
    //     Dropdown Button
    //   </Dropdown.Toggle>

    //   <Dropdown.Menu>
    //     <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
    //     <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
    //     <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
    //   </Dropdown.Menu>
    // </Dropdown>

    //             </nav>
    <Navbar bg="dark" data-bs-theme="dark" >
      <Container fluid>

        <Navbar.Brand className="mx-3" href="/">PROMOTOS</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
       
        <Navbar.Collapse id="navbarScroll" style={{ justifyContent: 'flex-end' }} >
      
          <Nav
            // className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px', justifyContent: 'flex-end', alignItems: 'flex-end' }}
            navbarScroll
          >

            {/* <Nav.Link href="#action1">Home</Nav.Link> */}
            {/* <Nav.Link href="#action2">Link</Nav.Link> */}
            
            <NavDropdown title={nom} id="navbarScrollingDropdown">
            {/* {user.user.role=="user" && <NavDropdown.Item href="/profile">Profil</NavDropdown.Item>}
            {user.user.role=="user" &&   <NavDropdown.Item href="/commande"> Tableau de bord</NavDropdown.Item>}
            {user.user.role=="user" &&   <NavDropdown.Divider />}
            <NavDropdown.Item href="#action5" onClick={() => Logout()}>  Déconnexion </NavDropdown.Item> */}
              
            </NavDropdown>
         
            <Nav.Link href="/cart">
              <div className="nav-bag mx-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-handbag-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 1a2 2 0 0 0-2 2v2H5V3a3 3 0 1 1 6 0v2h-1V3a2 2 0 0 0-2-2zM5 5H3.36a1.5 1.5 0 0 0-1.483 1.277L.85 13.13A2.5 2.5 0 0 0 3.322 16h9.355a2.5 2.5 0 0 0 2.473-2.87l-1.028-6.853A1.5 1.5 0 0 0 12.64 5H11v1.5a.5.5 0 0 1-1 0V5H6v1.5a.5.5 0 0 1-1 0V5z" />
                </svg>
                <span className="bag-quantity">
                  <span>{cartTotalQuantity}</span>
                </span>



              </div>
            </Nav.Link>

          </Nav>

        </Navbar.Collapse>

      </Container>
    </Navbar>
  );
};

export default NavBar;
