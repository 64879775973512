import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { network } from "../constants";
const initialState = {
  agents: [],
  status: null,
};

export const agentsFetch = createAsyncThunk(
  "agents/agentsFetch",
  async () => {
    try {
      const user = localStorage.getItem('user')
      const user1 = JSON.parse(user)
      const response = await axios.get(
        `${network.serverip}/Tiers`,{
        headers:{
          'Content-Type':'application/json',
          'Authorization':'Bearer '+ user1.user.Token
        }
    });
      // console.log(response.data)
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const agentSlice = createSlice({
  name: "agent",
  initialState,
  reducers: {},
  extraReducers: {
    [agentsFetch.pending]: (state, action) => {
      state.status = "pending";
    },
    [agentsFetch.fulfilled]: (state, action) => {
   
      state.agents = action.payload;
      state.status = "success";
    },
    [agentsFetch.rejected]: (state, action) => {
      state.status = "rejected";
    },
  },
});

export default agentSlice.reducer;
