import React from 'react';
import { BsJustify, BsPersonCircle, BsCartFill } from 'react-icons/bs';
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
const Header = ({ OpenSidebar }) => {
    const role = localStorage.getItem('Type');
    const user = localStorage.getItem('user');
    const { cartTotalQuantity } = useSelector((state) => state.cart);
    const history = useHistory()
    const Logout = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('session');
        localStorage.removeItem('Type');
        localStorage.removeItem('user');
        localStorage.removeItem("cartItems");
        history.push('/Login')
        // window.location.href = '/Login';
        // dispatch(setCurrentUser({}));
    }
    return (
        <header className='header'>
            <div className='menu-icon'>
                <BsJustify className='icon' onClick={OpenSidebar} />
            </div>
            {window.innerWidth > 1000 &&
                <div className='header-left mx-2'>
                    BANQUE ZITOUNA
                </div>
            }
            <div className='header-right d-flex justify-content-end align-items-center mt-3'>
                <ul className="navbar-nav flex-row mb-2  mb-lg-0">

                    <li className="nav-item dropdown me-5">
                        <a className="nav-link dropdown-toggle second-text fw-bold d-flex align-items-center" href="#" id="navbarDropdown"
                            role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <BsPersonCircle className='icon me-1' /> {user} {/* `me-1` for margin right */}
                        </a>
                        

                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a className="dropdown-item" href="/profile">Profile</a></li>
                                {role === 'user' &&    <li><a className="dropdown-item" href="/commande">Tableau de bord</a></li>}
                                <li><a className="dropdown-item" onClick={() => Logout()}>Déconnexion</a></li>
                            </ul>
                        

                    </li>
                    <li className="nav-item ">
                        <a className="nav-link me-5" href="/cart">
                            <BsCartFill className='icon' />
                            <span className="badge bg-primary rounded-pill"> {cartTotalQuantity}</span> {/* Example badge for cart value */}
                        </a>
                    </li>
                </ul>
            </div>
        </header>
    );
}

export default Header;
