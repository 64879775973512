import React from "react";
import { useEffect, useRef, useState } from "react";
import { network } from "../constants";
import { useLocation } from "react-router-dom";
import 'react-phone-number-input/style.css'

import ReactToPrint from "react-to-print";
import axiosInstance from './api';



import { Col, Row, Button} from "react-bootstrap";

import image from '../assets/zitouna.PNG'
const Devis = (props) => {

  const cmd = props.cmd;
    const componentRef = useRef();
  const location = useLocation();
 
 
  const [Nf, setNf] = useState('');
  const [MDate, setMDate] = useState('');
  const [nom, setnom] = useState("");
  const [agent, setagent] = useState("");
  const [agence, setagence] = useState("");
  const [adresse, setadresse] = useState("");
  const [cin, setcin] = useState("");
  const [TotTTC, setTotTTC] = useState(0);
  const [TotHT, setTotHT] = useState(0);
  const [TotTVA, setTotTVA] = useState(0);
  const [products,setproducts]=useState([]);

  useEffect(() => {
    axiosInstance.get(`/commandes/${cmd.Nf}`)
      
      .then((res) => {
        
        setNf(res.data.Nf)
        setnom(res.data.LibTiers)
        setadresse(res.data.Adresse)
        setcin(res.data.AssujTiers)
        setTotTTC(res.data.TotTTC)
        setTotHT(res.data.TotHT)
        setTotTVA(res.data.TotTva)
        setagent(res.data.Nom +' '+ res.data.Prenom)
        setagence(res.data.Remarq)
        setMDate(new Date(res.data.MDate).toLocaleDateString())

      });
  }, [location.state])

  useEffect(() => {
    axiosInstance.get(`/LigCmd/${cmd.Nf}`)
      
      .then((res) => {
       setproducts(res.data)
       
       
       


      });
  }, [location.state])
 
  return (
    <>
       <div className="invoice__preview bg-white p-5 rounded-2xl border-4 border-blue-200">
          <ReactToPrint
            trigger={() => (
              <Button>
                Print / Download
              </Button>
            )}
            content={() => componentRef.current}
          />

          <div ref={componentRef} className="page p-5"  >
            {/* header  */}
            <h1 className="d-flex justify-content-center font-bold">Devis: {Nf}</h1>
           
            <div className="line"></div>
            <section className="flex flex-col items-end justify-end">
              <h4 className="font-bold text-3xl uppercase mb-1">PROMOTOS</h4>
              <div>Adresse : Route Gremda km 4.5</div>
              <div>Téléphone : +216 74 212 200</div>
            </section>
            <Row className="my-2 py-5">
              <Col>
                <div className="flex flex-col">
                  <div >Date du Devis : {MDate}</div>
                  <div> Référence du Devis: {Nf} </div>
                  <div> Emis par : {agent} </div>
                  <div> Agence : {agence}</div>
                </div>
              </Col>
              {/* <MainDetails  */}
              <Col>
                <div className="flex flex-col ">
                    <h3>Informations client :</h3>
                  <div ><strong>Raison sociale : {nom}</strong></div>
                  <div> <strong>Adresse :{adresse}</strong> </div>
                  <div><strong>CIN: {cin}</strong></div>
                  
                </div>
              </Col>
            </Row>
            
              <div  style={{height:"450px"}}>
              <table class="table">
              <thead class="table-light">
                    <tr className="bg-gray-300 p-1">
                      <td className="font-bold">Description</td>
                      <td className="font-bold">Quantite </td>
                      <td align='center' className="font-bold">Prix.HT</td>
                      <td align='center' className="font-bold">%TVA</td>
                      <td align='center' className="font-bold">Prix.TTC</td>
                    </tr>
                  </thead>

                  {products.map((item) => (

                    <tbody>
                      <tr className="h-8">
                        <td>{item.LibArt}</td>
                        <td align='center'>{item.cartQuantity}</td>
                        <td align='center'>{item.PuHT.toFixed(3)}</td>
                        <td align='center'>{item.TVA}</td>
                        <td align='center'>{(item.PuHT * (1+(item.TVA/100))).toFixed(3)}</td>
                      </tr>
                    </tbody>


                  ))}
                </table>
                <Row>
                  <Col></Col>
                  <Col></Col>
                 
                  <Col >
                  <table className="table border" >
                  <thead className="table-light bg-gray-500">
                    <tr >
                     
                      <td  align='left' className=" p-1 font-bold">Total HT  </td>
                      <td align='right'>{TotHT.toFixed(3)}</td> 
                   
                    </tr>
                    <tr>
                    <td align='left' className="font-bold">TVA </td>
                    <td align='right'>{TotTVA.toFixed(3)}</td>
                    </tr>
                    <tr>
                    <td align='left' className="font-bold">Total TTC</td>
                    <td align='right'>{TotTTC.toFixed(3)}</td>
                    </tr>
                  </thead>
                  </table></Col>
                </Row>
                
           



              
            </div>
            <footer className="mt-5 bg-body-tertiary text-center text-lg-start">
            <div className="line"></div>
            <div className="text-center">
                  <Row>
                    <Col align='left'>
                    <h4 className="font-bold text-3xl uppercase mb-1">PROMOTOS</h4>
              <div>Adresse : Route Gremda km 4.5</div>
              <div>Téléphone : +216 74 212 200</div>
              <div>MF : 125876E/A/M 000</div>
                    </Col>
                    <Col>
                    <img src={image}  />
                    
                    </Col>
                    
                    
                  </Row>
                  
                   
                  </div>

                </footer>
          </div>



        </div>
      
    </>
  );
}

export default Devis;
