import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, } from "react-redux";
import { Col, Row,Modal } from '@themesberg/react-bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {  faPlus} from '@fortawesome/free-solid-svg-icons';
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import AddAgence from './AddAgence';
import Sidebar from "./Sidebar";

import Header from './Header';
import axiosInstance from './api';

const ListAgence = () => {
 
  const[Titre,setTitre]=useState('')
 const[agences,setagences] =useState([])
  const [show, setShow] = useState(false);
  const [agent,setagent]=useState([])
 

useEffect(async()=>{
  await axiosInstance.get(`/Agence`)
  .then((res)=>{
     setagences(res.data)
  })
},[])









  const handleClose = () => setShow(false);


  const Delete = (id) => {
    const MySwal = withReactContent(Swal)

    MySwal.fire({
      title: 'vous êtes sûr de la suppression ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui'
    })
      .then((result) => {
        if (result.isConfirmed) {
          axiosInstance.delete(`/Agence/${id}`)
          MySwal.fire(
            'Suppression avec succès !',
            '',
            'success'
          ).then((res) => {
            if (res.isConfirmed) {

              window.location.reload()
            }
          })

        }
      })
  }

 
 

  const { SearchBar } = Search;
 

  const columns = [
   {
    dataField: 'DR',
    text: 'DR',
    filter: textFilter(),
    style: { 'width': '150px' }
   },
      {
        dataField: 'code',
        text: 'Code',
        filter: textFilter(),
        style: { 'width': '150px' }
     
      },
  {
    dataField: 'label',
    text: 'Agence',
    filter: textFilter(),
  },
 
   
  
   {
        dataField: 'Directeur',
        text: 'Directeur',
      },
  
  
  {
    dataField:'act',
    text  : 'Action',
    editable: false,
    formatter: (cellContent, row) => {
      return (
        <div  className='d-flex justify-content-around'>
         <button title='Editer' className='btn btn-primary me-2' size="sm" 
        
        onClick={()=>{setShow(true);setagent(row);setTitre('Modifier')}} >
         <i className='bi bi-pencil'></i>
         </button>

          <button title='Supprimer' className='btn btn-danger'  size="sm" 
           onClick={()=>Delete(row.code)} 
          >
           <i className='bi bi-trash'></i>
           </button>
        </div>
      
      )
    },
   
  }
  





  ];


  const [openSidebarToggle, setOpenSidebarToggle] = useState(false)

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle)
  }

  return (
    <>
    
    <div className='grid-container'>
      <Header OpenSidebar={OpenSidebar}/>
      <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar}/>
      <main className='main-container'>
      <div >
      <Row className='text-center my-3'>
        <Col md={6} className='my-3'><h4 style={{ fontSize: '2.2em', textDecoration: 'underline' }}>Liste des Agences Banques</h4></Col>

     
    
      {/* <div className='my-3 mx-3' style={{ backgroundColor: "#eaf9ff" }} > */}
        {/* <Row className="mx-2 my-3 justify-content-center " > */}

        <Col >
              <button  className='btn btn-success pe-2'  size="sm" style={{margin:10}} onClick={()=>{setShow(true);setagent([]);setTitre('Ajouter')}} >Ajouter
           <FontAwesomeIcon className='px-2' icon={faPlus} /> </button>
              </Col>

        
      {/* </div> */}
      </Row>
      </div>
      {agences &&
      <ToolkitProvider
        keyField="Matricule"
        data={agences}
        columns={columns}
        search
        exportCSV={{
          onlyExportFiltered: true, exportAll: false,
          fileName: "Liste_Commandes.csv",
          blobType: 'text/plain;charset=utf-8 ',
          noAutoBOM: false,
          separator: ";"
        }}
      >
        {
          props => (<div className='mx-2 mb-4 '>
            <Row>
              <Col md={2}>
                <SearchBar {...props.searchProps} placeholder="rechercher ..." className='border-primary mx-3 mb-3 w-500' />
              </Col>
             
              {/* <Col md={2}>
                <ExportCSVButton variant="primary" {...props.csvProps} ><FontAwesomeIcon color='green' icon={faFileExcel} />  Exporter CSV </ExportCSVButton>
              </Col>*/}
            </Row> 
            <Row xs="auto" className='mx-3 mb-4 justify-content-start'>
              <Col>
                <BootstrapTable
                  striped
                  hover
                  pagination={paginationFactory()}
                  filter={filterFactory()}
                  {...props.baseProps}

                />
              </Col>
            </Row>
          </div>
          )
        }

      </ToolkitProvider>
}
      <Modal
        className='modal'
        dialogClassName='modal-container'
        aria-labelledby="contained-modal-title-vcenter"
        show={show}
        //  onHide={handleClose}
        size='lg'
      >
       <Modal.Header  style={{ backgroundColor: '#DCDCDC' }}>
        <Modal.Title style={{ backgroundColor: '#DCDCDC', color: 'white' }}>
          <span class="gray"> {Titre} Agence </span> 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
               <AddAgence 
               agent={agent} 
               onPress={handleClose}/>
          {/* <AddEmployer Emp={Emp} /> */}

        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: '#DCDCDC' }}>

        </Modal.Footer>
      </Modal>
</main>
      </div>
    

</>
  );
}


export default ListAgence;





