import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  cartItems: localStorage.getItem("cartItems")
    ? JSON.parse(localStorage.getItem("cartItems"))
    : [],
  cartTotalQuantity: 0,
  cartTotalAmount: 0,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart(state, action) {
   
      const existingIndex = state.cartItems.findIndex(
        (item) => item.IDArt === action.payload.IDArt
      );
// console.log(existingIndex)
//       if (existingIndex >= 0) {
//         state.cartItems[existingIndex] = {
//           ...state.cartItems[existingIndex],
//           cartQuantity: state.cartItems[existingIndex].cartQuantity + 1,
//         };
//         toast.info("Mise a jour quantité de produits", {
//           position: "bottom-left",
//         });
//       } else {
         let tempProductItem = { ...action.payload,
         cartQuantity: 1 };
        // let tempProductItem = { 
        //   IDArt :action.payload.IDArt,
        //   LibArt : action.payload.LibArt,
        //   ExLibArt : action.payload.ExLibArt,
        //   cartQuantity: 1,
        //   PuHT : action.payload.PuHT,
        //   PuTTC :action.payload.PrixTTC,
        //   Tva : action.payload.Tva

        // }
        console.log(tempProductItem)
        
        state.cartItems.push(tempProductItem);
        toast.success("Produit ajouté au panier", {
          position: "bottom-left",
        });
      
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    decreaseCart(state, action) {
      const itemIndex = state.cartItems.findIndex(
        (item) => item.IDArt=== action.payload.IDArt
      );

      if (state.cartItems[itemIndex].cartQuantity > 1) {
        state.cartItems[itemIndex].cartQuantity -= 1;

        toast.info("Mise a jour quantité de produits", {
          position: "bottom-left",
        });
      } else if (state.cartItems[itemIndex].cartQuantity === 1) {
        const nextCartItems = state.cartItems.filter(
          (item) => item.IDArt !== action.payload.IDArt
        );

        state.cartItems = nextCartItems;

        toast.error("Produit retiré du panier", {
          position: "bottom-left",
        });
      }

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    removeFromCart(state, action) {
      state.cartItems.map((cartItem) => {
        if (cartItem.IDArt === action.payload.IDArt) {
          const nextCartItems = state.cartItems.filter(
            (item) => item.IDArt !== cartItem.IDArt
          );

          state.cartItems = nextCartItems;

          toast.error("Produit retiré du panier", {
            position: "bottom-left",
          });
        }
        localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        return state;
      });
    },
    getTotals(state, action) {
      let { total, quantity } = state.cartItems.reduce(
        (cartTotal, cartItem) => {
          const { price, cartQuantity } = cartItem;
          const itemTotal = price * cartQuantity;

          cartTotal.total += itemTotal;
          cartTotal.quantity += cartQuantity;

          return cartTotal;
        },
        {
          total: 0,
          quantity: 0,
        }
      );
      total = parseFloat(total.toFixed(2));
      state.cartTotalQuantity = quantity;
      state.cartTotalAmount = total;
    },
    clearCart(state, action) {
      state.cartItems = [];
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      toast.error("vider panier", { position: "bottom-left" });
    },
  },
});

export const { addToCart, decreaseCart, removeFromCart, getTotals, clearCart } =
  cartSlice.actions;

export default cartSlice.reducer;
