import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from './components/auth';

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Redirect to="/" />
      ) : (
        <Component {...props} />
      )
    }
  />
);

export default PublicRoute;