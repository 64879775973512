import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { network } from "../constants";
import axiosInstance from '../components/api';

const initialState = {
  agences: [],
  status: null,
};

export const agencesFetch = createAsyncThunk(
  "agences/agencesFetch",
  async () => {
    try {
   
      const response = await axiosInstance.get(`/Agence`)
      
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const agencesSlice = createSlice({
  name: "agence",
  initialState,
  reducers: {},
  extraReducers: {
    [agencesFetch.pending]: (state, action) => {
      state.status = "pending";
    },
    [agencesFetch.fulfilled]: (state, action) => {
   
      state.agences = action.payload;
      state.status = "success";
    },
    [agencesFetch.rejected]: (state, action) => {
      state.status = "rejected";
    },
  },
});

export default agencesSlice.reducer;
