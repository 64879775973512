export const VilleOptions = [
    
    {id:'1', label:'Ariana'},
    {id:'2',label:'Béja'},
    {id:'3',label: 'Ben Arous'},
    {id:'4', label:'Bizerte'},
    {id:'5',label: 'Gabés'},
    {id:'6',label: 'Gafsa'},
    {id:'7',label: 'Jendouba'},
    {id:'8',label: 'Kairouan'},
    {id:'9',label:  'Kasserine'},
    {id:'10',label:  'Kébili'},
    {id:'11', label: 'Kef'},
    {id:'12', label: 'Mahdia'},
    {id:'13', label: 'Manouba'},
    {id:'14', label: 'Médenine'},
    {id:'15', label: 'Monastir'},
    {id:'16',label:  'Nabeul'},
    {id:'17', label: 'Sfax'},
    {id:'18',label:  'Sidi Bouzid'},
    {id:'19',label:  'Siliana'},
    {id:'20',label:  'Sousse'},
    {id:'21',label:  'Tataouine'},
    {id:'22',label:  'Tozeur'},
    {id:'23',label: 'Tunis'},
    {id:'24',label: 'Zaghouan'},
    

 ] ;
  export const ClientOptions = [
    {id:'1', label:'Particulier'},
    {id:'2',label:'Professionnel'},
    {id:'3',label: 'Entreprise'}
  ]

  export const StatusOptions = [
   
    {id:'0', label:'En Attente'},
    {id:'1',label:'Validée'},
    {id:'2',label:'Confirmée'},
    {id:'3',label:'Livrée'},
    {id:'5', label:'Annulée'},
    // {id:'3',label: 'Entreprise'}
  ]

  export const MarqueOptions = [
    {id:'0', label:'LX04'},
    {id:'1',label:'LX08'},
    // {id:'3',label: 'Entreprise'}
  ]
   
  export const RoleOptions = [
    {id:'0', label:'user'},
    {id:'1',label:'admin'},
    // {id:'3',label: 'Entreprise'}
  ]