import React,{useEffect, useState} from 'react'
import { Col, Row,Form } from '@themesberg/react-bootstrap';

import { useSelector, } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import { Typeahead } from 'react-bootstrap-typeahead'
import {MarqueOptions} from '../data.js'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';
import Sidebar from "./Sidebar";
import Header from './Header.jsx';
import axiosInstance from './api';




const ListArticles = () => {
    const [items,setitems] = useState([])
    const [Allproducts,setAllproducts]=useState([])
    const [Marque,setMarque]=useState([])


 useEffect(()=>{
  axiosInstance.get(`/articles`)
  .then((res)=>{
    setitems(res.data)
    setAllproducts(res.data)
  })
  
  
 },[])
 



 


  const { SearchBar } = Search;
 

  const columns = [
    {
        dataField: 'CodArt',
        text: 'Code',
     
      },
      {
        dataField: 'LibArt',
        text: 'désignation',
      },
      
   {
    dataField: 'Qt',
    text: 'Stock',
    style:{color:'green'},
    // filter: textFilter(),
    

  },
      {
        dataField: 'LibFam',
        text: 'Famille',
     
      },
      {
        dataField: 'DesSFam',
        text: 'Sous Famille',
     
      },
 
  {
    dataField: 'Collection',
    text: 'Couleur',
    // footer: 'Total :',
    align: 'center',
    style:{color:'red'},
    // filter: textFilter(),
    
  },
   {
    dataField: 'PrixTTC',
    text: 'PrixTTC',
    formatter: (cell) => (Number.parseFloat(cell).toFixed(3)),
    // filter: textFilter(),
   

   }
  // ,
  
  
  
  
  // {
  //   dataField:'act',
  //   text  : 'Action',
  //   editable: false,
  //   formatter: (cellContent, row) => {
  //     return (
  //       <div  className='d-flex justify-content-around'>
  //        <button title='Editer' className='btn btn-primary me-2' size="sm" 
        
  //       // onClick={()=>{setShow(true);setagent(row);setTitre('Modifier')}} 
  //       >
  //        <i className='bi bi-pencil'></i>
  //        </button>

  //         <button title='Supprimer' className='btn btn-danger'  size="sm" 
  //         //  onClick={()=>Delete(row.Matricule)} 
  //         >
  //          <i className='bi bi-trash'></i>
  //          </button>
  //       </div>
      
  //     )
  //   },
   
  // }
  





  ];

useEffect(()=>{
  if(Marque.length>0)
    {
      const data = items.filter(row =>  row.DesSFam==Marque [0]['label'])
      setAllproducts(data)
    }else{
      setAllproducts(items)
    }

},[Marque])

const [openSidebarToggle, setOpenSidebarToggle] = useState(false)

const OpenSidebar = () => {
  setOpenSidebarToggle(!openSidebarToggle)
}

  return (
   
    <div className='grid-container'>
      <Header OpenSidebar={OpenSidebar}/>
      <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar}/>
      <main className='main-container'>
      <div >
        
      
      <Row className='text-center my-3'>
        <Col md={6} className='my-3'><h4 style={{ fontSize: '2.2em', textDecoration: 'underline' }}>Liste des produits disponibles</h4></Col>

     
    
      {/* <div className='my-3 mx-3' style={{ backgroundColor: "#eaf9ff" }} > */}
        {/* <Row className="mx-2 my-3 justify-content-center " > */}

        <Col >
            
              </Col>

        
      {/* </div> */}
      </Row>
      </div>
      <ToolkitProvider
        keyField="Matricule"
        data={Allproducts}
        columns={columns}
        search
        exportCSV={{
          onlyExportFiltered: true, exportAll: false,
          fileName: "Liste_Articles.csv",
          blobType: 'text/plain;charset=utf-8 ',
          noAutoBOM: false,
          separator: ";"
        }}
      >
        {
          props => (<div className='mx-2 mb-4 '>
            <Row className='my-2'>
              <Col md={4}>
                <SearchBar {...props.searchProps} placeholder="rechercher ..." className='border-primary mx-2 mt-2 mb-3 w-500' />
              </Col>
              <Col md={2}>
              <Form.Group >
               <Form.Label>Sous Famille</Form.Label> 
              <Typeahead
                  id="my-typeahead-id"
                  labelKey="label"
                  // className='mt-4'
                  // required
                  // ref={RefDR}
                  defaultValue={Marque}
                  onChange={(item)=>{setMarque(item)}}
                  options={MarqueOptions}
                  placeholder="--Tout--"
                  selected={Marque}
                  />
                  </Form.Group>
              </Col>
              {/* <Col md={2}>
                <ExportCSVButton variant="primary" {...props.csvProps} ><FontAwesomeIcon color='green' icon={faFileExcel} />  Exporter CSV </ExportCSVButton>
              </Col>*/}
            </Row> 
            <Row xs="auto" className='mx-3 mb-4 justify-content-start'>
              <Col>
                <BootstrapTable
                  striped
                  hover
                  // pagination={paginationFactory()}
                  filter={filterFactory()}
                  {...props.baseProps}

                />
              </Col>
            </Row>
          </div>
          )
        }

      </ToolkitProvider>

    

      </main>
    </div>
  );
}


export default ListArticles;





