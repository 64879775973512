
import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api.scooter-promotos.tn:8089/api', // replace with your backend URL
});

// Function to refresh access token
const refreshAccessToken = async () => {
 
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      throw new Error('No refresh token available');
    }

    const response = await api.post('/refresh-token', {
      refreshToken,
    });
   
    
    const { accessToken, newRefreshToken } = response.data;

    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', newRefreshToken);

    return accessToken;
  } catch (error) {
    console.error('Failed to refresh access token:', error);
    // Handle logout or redirect to login page
    throw error; // Optionally handle this in your components
  }
};

export { api, refreshAccessToken };