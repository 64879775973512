
import React, { useEffect } from 'react';
import { Alert, Button, Form, InputGroup } from "react-bootstrap";

import { useState,useRef } from "react";
import { api, refreshAccessToken } from './util';
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import backgroundImage from "../assets/Log.jpg";
import bs from "../assets/promotosLogo.PNG";

import { useHistory } from "react-router-dom";

import { useDispatch } from "react-redux";
import { loginUser } from "../slices/userSlice";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { decode as jwt_decode } from 'jsonwebtoken';

// import jwt_decode from "jwt-decode";
// import axiosInstance from "./api"
const Login = () => {
  
  const [showpassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

 const [error,setError]=useState("")
 const history=useHistory()
 const dispatch = useDispatch()
 const Refpwd = useRef()
  const Refnom = useRef()

useEffect(()=>{
  Refnom.current.focus()
},[])
  const handleSubmit = async (e) => {
  
    e.preventDefault();
    let user ={
      email,password
     }
     if (!email || !password) {
      setError('Veuillez remplir tous les champs.'); // Set error message for empty fields
      return;
    }
  
    try { 

      const response = await api.post('/Login', user);

      
      const { msg,success,Token, refreshToken} = response.data;


if(success ===false){
  setError(msg)
}else
{
      localStorage.setItem('accessToken', Token);
      localStorage.setItem('refreshToken', refreshToken);
      
      const decodedToken = jwt_decode(Token);
      if(decodedToken)
        {
          console.log(decodedToken)
          localStorage.setItem('session',decodedToken.user.recordset[0]['Matricule']);
          localStorage.setItem('Type',decodedToken.user.recordset[0]['role']);
          localStorage.setItem('user',decodedToken.user.recordset[0]['agent']);


        }
     
      
       
          history.push('/')
       
}
    } catch (error) {
      // setError('Wrong username or password');
    }
  };


   
    
  return (
   
    <div className="loginPage d-flex">
    <div className={window.innerWidth > 1000?"leftPart":"leftPart2"}>
      <div className="leftPart-container">
        <img
          className="bs-image"
          alt="bs"
          src={bs}
          style={{ borderRadius: "10px" }}
        />
        <h1 style={{ color: "#252C34", fontSize: "5vh", marginTop: "1%" }}>
           Bienvenue chez nous
        </h1>
        
        <div className={window.innerWidth > 1000?" login-form-container":" login-form-container2"}>
          <AccountCircleRoundedIcon
            className="icon-img"
            sx={{ fontSize: "10vh" }}
            style={{ margin: 0 }}
          />
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="label-form">Matricule</Form.Label>
              <Form.Control
               ref={Refnom}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={(e) => {

                  if (e.keyCode === 13) {
                    Refpwd.current.focus()
                  }
                }}
                required
                // name="email"
                className="input-form  rounded"
                size="lg"
                type="numeric"
                // placeholder="Entrer votre Matricule"
                style={{ marginBottom: "1%" }}
              />
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label className="label-form">Mot de passe</Form.Label>
              <InputGroup>
                <Form.Control
                  ref={Refpwd}
                  onChange={(e) => setPassword(e.target.value)}
                  aria-describedby="basic-addon2"
                  name="password"
                  className=" input-form"
                  size="lg"
                  type={showpassword ? "text" : "password"}
                  // placeholder="Entrer votre mot de passe"
                  required
                />
               <Button
                    onClick={() => setShowPassword(!showpassword)}
                    size="lg"
                    className="visiblepass"
                    variant="outline-secondary"
                  >
                    {showpassword ? (
                      <VisibilityIcon className="iconPassword" />
                    ) : (
                      <VisibilityOffIcon className="iconPassword" />
                    )}
                  </Button>
              </InputGroup>
            </Form.Group>
            <Button
               onClick={handleSubmit}
              className="login-button"
              size="lg"
              variant=" btn-block"
              type="submit"
            >
              Se connecter
            </Button>
            {
              error && <div className='my-2  alert alert-danger' role='alert'>{error}</div>
            }
          </Form>
          <p
            // onClick={(e)=> login(e)}
            style={{
              color: "black",
              fontSize: "20px",
              marginTop: "10px",
              cursor: "pointer",
            }}
          >
        
          </p>
        </div>
        <Alert
          show={open}
          className="alert-form"
          variant="danger"
          onClose={() => setOpen(false)}
          dismissible
        >
          Veuillez vérifier votre email ou/et votre mot de passe
        </Alert>
      </div>
    </div>
    {  window.innerWidth > 1000 &&
    <div className="rightPart">

      <img
        className="background-image"
        alt="background"
        src={backgroundImage}
      />
  
    </div>
   }
  </div>
  );
}

export default Login;
