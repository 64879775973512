
import { Col, Row, Button, Form, InputGroup } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import PhoneInput from 'react-phone-number-input'
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useHistory, useLocation } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import axiosInstance from './api';
import Header from "./Header";

import { Link } from 'react-router-dom';
const Profile = () => {
  const location = useLocation();
  const history = useHistory();
  const id = localStorage.getItem('session')

 
  
  useEffect(() => {

    if(id)
      {
        axiosInstance.get(`/Tiers/${id}`)
     
      .then((res) => {
        
        setAgence(res.data.Agence)
        setMatricule(res.data.Matricule)
        setnom(res.data.Nom)
        setprenom(res.data.Prenom)
        setPassword(res.data.pwd)
        setCodeAgence(res.data.CodeAgence) 
        setTel(res.data.Tel)
        setFonction(res.data.Fonction)
        setDr(res.data.DR)
      })
    }
  }, [location])



  const [Matricule, setMatricule] = useState("");
  const [Dr, setDr] = useState("");

  const [Tel, setTel] = useState("");

  const [Fonction, setFonction] = useState("");
  const [nom, setnom] = useState("");
  const [prenom, setprenom] = useState("");
  const [password, setPassword] = useState('');
  const [Agence, setAgence] = useState('');
  const [CodeAgence, setCodeAgence] = useState('');
  const [showpassword, setShowPassword] = useState(false);
 
  const Refpwd = useRef()
  const Refnom = useRef()
  const Refprenom = useRef()
  const RefTel = useRef()
  const RefCodAg = useRef()
  const RefAgence = useRef()
  const RefDrAgence = useRef()
  const valider = () => {
    const Agent = {
   
      pwd: password,
      nom: nom,
      prenom: prenom,
      CodeAgence: parseInt(CodeAgence),
      Agence: Agence,
      Tel: Tel,
      Fonction: Fonction,
      DR : Dr
     
     

    }
    const MySwal = withReactContent(Swal)
    MySwal.fire({
      title: 'Confirmation',
      text: `voulez-vous enregistrer?`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non'
    }).then((res) => {
      if (res.isConfirmed) {
        axiosInstance.put(`/Tiers/${id}`,Agent)
      
          .then((data) => {
            MySwal.fire(
              'Modification avec succès   !',
              'Modification .',
              'success'
            ).then((res) => {
              if (res.isConfirmed) {

                history.push('/')
              }
            })
          })
          .catch((error) => {
            const MySwal = withReactContent(Swal)

            MySwal.fire({
              title: 'error?',
              text: ` Error de Serveur `,
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'ok'
            })


          });
       
      }
    })


  }


  return (
    <>
   { id &&
    <div>
      <Header />
      <div className="start-shopping mx-5">
               <Link to="/">
                 <svg
                   xmlns="http://www.w3.org/2000/svg"
                   width="20"
                   height="20"
                   fill="currentColor"
                   className="bi bi-arrow-left"
                   viewBox="0 0 16 16"
                 >
                   <path
                     fillRule="evenodd"
                     d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                   />
                 </svg>
                 <span>Retour</span>
               </Link>
             </div>
      <div className="container my-5 py-5 border">
     
        <h3 >Information du compte : </h3>

        <Row className="my-2">
          <Col md={6}>
            <Form.Group >
              <Form.Label>Matricule</Form.Label>
              <Form.Control
                disabled
                required
                //    ref={Refnom}
                value={Matricule}
                onChange={(e) => setMatricule(e.target.value)}
                onKeyDown={(e) => {

                  if (e.keyCode === 13) {
                    Refpwd.current.focus()
                  }
                }}
                name="prenom"
                className="inputStyle input-color border border-dark"
                type="text"
                placeholder="Entrer Matricule"
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group >
              <Form.Label>Mot de passe</Form.Label>
              <InputGroup>
                <Form.Control
                  required
                  ref={Refpwd}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={(e) => {

                    if (e.keyCode === 13) {
                      RefCodAg.current.focus()
                    }
                  }}
                  name="prenom"
                  className="inputStyle input-color border border-dark"
                  type={showpassword ? "text" : "password"}
                  placeholder="Entrer Nom"
                />
                <Button
                  onClick={() => setShowPassword(!showpassword)}

                  className="visiblepass"
                  variant="outline-secondary"
                >
                  {showpassword ? (
                    <VisibilityIcon className="iconPassword" />
                  ) : (
                    <VisibilityOffIcon className="iconPassword" />
                  )}
                </Button>
              </InputGroup>
            </Form.Group>
          </Col>


        </Row>
        <Row className="my-2">
          <Col md={6}>
            <Form.Group >
              <Form.Label>Nom</Form.Label>
              <Form.Control
                required
                ref={Refnom}
                value={nom}
                onChange={(e) => setnom(e.target.value)}
                onKeyDown={(e) => {

                  if (e.keyCode === 13) {
                    Refprenom.current.focus()
                  }
                }}
                name="prenom"
                className="inputStyle input-color border border-dark"
                type="text"
                placeholder="Entrer Nom"
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group >
              <Form.Label>Prénom </Form.Label>
              <Form.Control
                required
                ref={Refprenom}
                value={prenom}
                onChange={(e) => setprenom(e.target.value)}
                onKeyDown={(e) => {

                  if (e.keyCode === 13) {
                    RefTel.current.focus()
                  }
                }}
                name="prenom"
                className="inputStyle input-color border border-dark"
                type="text"
                placeholder="Entrer Prénom"
              />

            </Form.Group>
          </Col>

        </Row>


        <Row className="my-2">
          <Col md={6}>
            <Form.Group >
              <Form.Label> Agence</Form.Label>
              <Form.Control
                required
                disabled
                ref={RefAgence}
                value={Agence}
                onChange={(e) => setAgence(e.target.value)}
                onKeyDown={(e) => {

                  if (e.keyCode === 13) {
                    RefDrAgence.current.focus()
                  }
                }}
                name="prenom"
                className="inputStyle input-color border border-dark"
                type="text"
                placeholder="Entrer Directeur Agence"
              />
            </Form.Group>
          </Col>
          <Col md={6} >
            <Form.Group >
              <Form.Label>Téléphone</Form.Label>
              <PhoneInput
                className="py-2"
                style={{ width: "80%", }}
                required
                ref={RefTel}
                defaultCountry="TN"
                placeholder="Entrer numéro de Téléphone"
                value={Tel}

                onChange={setTel} />

            </Form.Group>
          </Col>


        </Row>

        <Row className="my-3">

          <Col >
            <Button
              onClick={valider}>

              Enregistrer
            </Button>
          </Col>
        </Row>


      </div>
    </div>
}
</>
  );
}

export default Profile;
