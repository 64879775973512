
import { Col, Row, Button, Form, InputGroup } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";


import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import {Typeahead} from 'react-bootstrap-typeahead'

import axiosInstance from "./api.jsx";

const AddAgence = ({agent, onPress}) => {
 
  const [Dr, setDr] = useState([]);
  const [error, setError] = useState('');
  const[AllDR,setAllDR]=useState([]);
  const [DrAgence, setDrAgence] = useState("");
  const [Agence, setAgence] = useState('');
  const [CodeAgence, setCodeAgence] = useState('');
  const [update,setupdate]= useState(true)
  // const history = useHistory();

  const RefCodAg = useRef()
  const RefDR = useRef()
  const RefAgence = useRef()
  const RefDrAgence = useRef()
  useEffect(() => {
   
    if (agent.length!=0) {
     
         
         
          setAgence(agent.label)
          setCodeAgence(agent.code)
          setDr(AllDR.filter(x=>x.label ===agent.DR))
          setDrAgence(agent.Directeur)
          RefAgence.current.focus()

    }
  }, [agent,AllDR])

useEffect(()=>{

})

  const valider = (e) => {
    e.preventDefault()
    if(!CodeAgence){
      setError('Veuillez entrer Code Agence bancaire!!')
      RefCodAg.current.focus()
    }else if(!Agence){ 
      setError('Veuillez entrer libellé agence bancaire !!')
      RefAgence.current.focus()

    }else if(DrAgence.length===0){
      setError('Veuillez entrer Directeur  !!')
      RefDrAgence.current.focus()
    }else if(!Dr){
      setError('Veuillez entrer DR  !!')
      RefDR.current.focus()
    }else{
   
   
    const MySwal = withReactContent(Swal)
    MySwal.fire({
      title: 'Confirmation',
      text: `voulez-vous enregistrer?`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non'
    }).then((res) => {
      if (res.isConfirmed) {
        const Agent = {
     
          CodeAgence: parseInt(CodeAgence),
          Agence: Agence,
          Dr: Dr.length===0?'': Dr [0]['label'],
          Directeur: DrAgence,
         
    
        }
        if(agent.length===0)
        {
          axiosInstance.post (`/Agence`, Agent)
          
          .then((data) => {
            MySwal.fire(
              'Création avec succès   !',
              'Création .',
              'success'
            ).then((res) => {
              if (res.isConfirmed) {

                window.location.reload();
              }
            })
          })
          .catch((error) => {
            const MySwal = withReactContent(Swal)

            MySwal.fire({
              title: 'error?',
              text: ` Error de Serveur `,
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'ok'
            })


          });
        }else{
          axiosInstance.put(`/Agence/${agent.code}`, Agent)
         
          .then((data) => {
            MySwal.fire(
              'Modification avec succès   !',
              'Modification .',
              'success'
            ).then((res) => {
              if (res.isConfirmed) {

                window.location.reload();
              }
            })
          })
          .catch((error) => {
            const MySwal = withReactContent(Swal)

            MySwal.fire({
              title: 'error?',
              text: ` Error de Serveur `,
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'ok'
            })


          });
        }
        
       
       
      }
    })
  }

  }

  useEffect(()=>{
    axiosInstance.get(`/DR`)
    
  .then((res) => {
   
    setAllDR(res.data)
  })
  },[])

  return (
    <>


      <div >
     

        {
              error && <div className='py-2 alert alert-danger' role='alert'>{error}</div>
            }
      
        <Row className="my-2">
          <Col md={6}>
            <Form.Group >
              <Form.Label>Code Agence</Form.Label>
              {
              agent.length ===0 ?(
              <Form.Control
               
                ref={RefCodAg}
                value={CodeAgence}
                onChange={(e) => {setCodeAgence(e.target.value);setupdate(false)}}
                onKeyDown={(e) => {

                  if (e.keyCode === 13) {
                    RefAgence.current.focus()
                  }
                }}
                name="prenom"
                className="inputStyle input-color border border-dark"
                type="number"
                placeholder="Entrer Code Agence"
                required
              />):(
                <Form.Control
               
                disabled
                ref={RefCodAg}
                value={CodeAgence}
                onChange={(e) =>{ setCodeAgence(e.target.value);setupdate(false)}}
                onKeyDown={(e) => {

                  if (e.keyCode === 13) {
                    RefAgence.current.focus()
                  }
                }}
                name="prenom"
                className="inputStyle input-color border border-dark"
                type="number"
                placeholder="Entrer Code Agence"
              />
              )}
            </Form.Group>
          </Col>

         



        </Row>

        <Row className="my-2">
          <Col md={6}>
            <Form.Group >
              <Form.Label> Agence</Form.Label>
              <Form.Control
              
                ref={RefAgence}
                value={Agence}
                onChange={(e) => {setAgence(e.target.value);setupdate(false)}}
                onKeyDown={(e) => {

                  if (e.keyCode === 13) {
                    RefDrAgence.current.focus()
                  }
                }}
                name="prenom"
                className="inputStyle input-color border border-dark"
                type="text"
                placeholder="Entrer libellé agence bancaire"
                required
              />
            </Form.Group>
          </Col>
         

        </Row>
        <Row className="my-2">
          <Col md={6}>
            <Form.Group >
              <Form.Label>Directeur Agence </Form.Label>
              <Form.Control
                
                ref={RefDrAgence}
                value={DrAgence}
                onChange={(e) => {setDrAgence(e.target.value);setupdate(false)}}
                  onKeyDown={(e) => {

                     if (e.keyCode === 13) {
                       RefDR.current.focus()
                     }
                   }}
                name="prenom"
                className="inputStyle input-color border border-dark"
                type="text"
                placeholder="Entrer Directeur Agence"
                required
              />

            </Form.Group>
          </Col>


        </Row>
        <Row className="my-2">
          <Col md={6}>
            <Form.Group >
              <Form.Label>DR</Form.Label>
              <Typeahead
                  // required
                  ref={RefDR}
                  defaultValue={Dr}
                  onChange={(item)=>{setDr(item);setupdate(false)}}
                  options={AllDR}
                  placeholder="----"
                  selected={Dr}
                  />
                    
            </Form.Group>
          </Col>
          

        </Row>
        <Row>
          <Col></Col>
          <Col align='right' >
          <button  className='btn btn-primary me-3'  size="sm"  
          type="submit" block
          disabled={update}
          onClick={valider}
          >

          Enregistrer
        </button>
       
          {/* onClick={valider} */}

       
       
        <button type="cancel" className='btn btn-danger' size="sm" 
          onClick={()=> onPress()}
        >
      Fermer
        </button> 
        </Col>
        </Row>
      
        
      </div>
    </>
  );
}

export default AddAgence;





