import React from 'react';

const Footer = () => {
  return (
    <div className='mt-5' style={{textAlign:'center'}}>
    
     © 2024 PROMOTOS | Tous droits réservés  | 
     <a class="text-reset fw-bold"  href='https://www.businessoftware.com.tn'> BUSINESS SOFTWARE</a>
    </div>
  );
}

export default Footer;
