import React, { useEffect, useState } from 'react'
import { Col, Row, Modal } from '@themesberg/react-bootstrap';
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

import Sidebar from "./Sidebar";
import AddAgent from './AddAgent';

import { useHistory } from "react-router-dom";
import axiosInstance from './api';
import Header from './Header';

const ListAgent = () => {

  const [Titre, setTitre] = useState('')

  const [show, setShow] = useState(false);
  const [agent, setagent] = useState([])
  const [agents, setagents] = useState([])

  const handleClose = () => setShow(false);

  useEffect(async() => {

    await axiosInstance.get(`/Tiers`)

      
      .then((res) => {
      //  console.log(res.data)
        setagents(res.data)


      });


  },[])

  const Delete = (id) => {
    const MySwal = withReactContent(Swal)

    MySwal.fire({
      title: 'vous êtes sûr de la suppression ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui'
    })
      .then((result) => {
        if (result.isConfirmed) {
          axiosInstance.delete(`/Tiers/${id}`)
          MySwal.fire(
            'Suppression avec succès  !',
            '',
            'success'
          ).then((res) => {
            if (res.isConfirmed) {

              window.location.reload()
            }
          })

        }
      })
  }



  const { SearchBar } = Search;


  const columns = [
    {
      dataField: 'DR',
      text: 'DR',
      filter: textFilter(),
      style: { 'width': '150px' }
    },
    {
      dataField: 'Agence',
      text: 'Agence',
      filter: textFilter(),
      style: { 'width': '250px' }

    },
    {
      dataField: 'Matricule',
      text: 'Matricule',
      filter: textFilter(),
      align: 'center',
      style: { 'width': '150px' }
      // filter: textFilter(),

    },
    {
      dataField: 'Nom',
      text: 'Nom',
      // filter: textFilter(),


    },
    {
      dataField: 'Prenom',
      text: 'Prénom',
      // filter: textFilter(),


    },
    {
      dataField: 'Fonction',
      text: 'Fonction',
    },
    {
      dataField: 'act',
      text: 'Action',
      editable: false,
      formatter: (cellContent, row) => {
        return (
          <div className='d-flex justify-content-around'>
            <button title='Editer' className='btn btn-primary me-2' size="sm"

              onClick={() => { setShow(true); setagent(row); setTitre('Modifier') }} >
              <i className='bi bi-pencil'></i>
            </button>

            <button title='Supprimer' className='btn btn-danger' size="sm"
              onClick={() => Delete(row.Matricule)}
            >
              <i className='bi bi-trash'></i>
            </button>
          </div>

        )
      },

    }






  ];

  const [openSidebarToggle, setOpenSidebarToggle] = useState(false)

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle)
  }

  return (
    
    

    
    <div className='grid-container'>
      <Header OpenSidebar={OpenSidebar}/>
      <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar}/>
      <main className='main-container'>
      <div >
        
   
        <Row className='text-center my-3'>
          <Col md={6} className='my-3'><h4 style={{ fontSize: '2.2em', textDecoration: 'underline' }}>Liste des Agents Banques</h4></Col>



          {/* <div className='my-3 mx-3' style={{ backgroundColor: "#eaf9ff" }} > */}
          {/* <Row className="mx-2 my-3 justify-content-center " > */}

          <Col >
            <button className='btn btn-success pe-2' size="sm" style={{ margin: 10 }} onClick={() => { setShow(true); setagent([]); setTitre('Ajouter') }} >Ajouter
              <FontAwesomeIcon className='px-2' icon={faPlus} /> </button>
          </Col>


          {/* </div> */}
        </Row>

        <ToolkitProvider
          keyField="Matricule"
          data={agents}
          columns={columns}
          search
          exportCSV={{
            onlyExportFiltered: true, exportAll: false,
            fileName: "Liste_Commandes.csv",
            blobType: 'text/plain;charset=utf-8 ',
            noAutoBOM: false,
            separator: ";"
          }}
        >
          {
            props => (<div className='mx-2 mb-4 '>
              <Row>
                <Col md={2}>
                  <SearchBar {...props.searchProps} placeholder="rechercher ..." className='border-primary mx-3 mb-3 w-500' />
                </Col>

                {/* <Col md={2}>
                <ExportCSVButton variant="primary" {...props.csvProps} ><FontAwesomeIcon color='green' icon={faFileExcel} />  Exporter CSV </ExportCSVButton>
              </Col>*/}
              </Row>
              <Row xs="auto" className='mx-3 mb-4 justify-content-start'>
                <Col>
                  <BootstrapTable
                    striped
                    hover
                    pagination={paginationFactory()}
                    filter={filterFactory()}
                    {...props.baseProps}

                  />
                </Col>
              </Row>
            </div>
            )
          }

        </ToolkitProvider>

        <Modal
          className='modal'
          dialogClassName='modal-container'
          aria-labelledby="contained-modal-title-vcenter"
          show={show}
          onHide={handleClose}
          size='lg'
        >
          <Modal.Header style={{ backgroundColor: '#DCDCDC' }} closeButton>
            <Modal.Title style={{ backgroundColor: '#DCDCDC', color: 'black', fontSize: 27 }}>

              {Titre} agent
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddAgent agent={agent}
              onPress={handleClose} />
            {/* <AddEmployer Emp={Emp} /> */}

          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: '#DCDCDC' }}>

          </Modal.Footer>
        </Modal>

      </div>
      </main>
    </div>
      
        
  );
}


export default ListAgent;



