
import { Col, Row, Button, Form, InputGroup } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import PhoneInput from 'react-phone-number-input'

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useHistory} from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { useSelector, } from "react-redux";
import { Typeahead } from 'react-bootstrap-typeahead'

import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {RoleOptions} from '../data.js'

import axiosInstance from "./api.jsx";
const AddAgent = ({ agent, onPress }) => {
  
  const { agences, status } = useSelector((state) => state.agences);
  
  const [error, setError] = useState('');
  const [update, setupdate] = useState(true)
  const [Matricule, setMatricule] = useState("");
  const [AllDR, setAllDR] = useState([]);
  const [Dr, setDr] = useState([]);
  const [role, setrole] = useState([]);
  const [Tel, setTel] = useState("");
  const [AllFonction, setAllFonction] = useState([]);
  const [Fonction, setFonction] = useState([]);
  const [nom, setnom] = useState("");
  const [prenom, setprenom] = useState("");
  const [password, setPassword] = useState('');
  const [AllAgence, setAllAgence] = useState([]);
  const [Agence, setAgence] = useState([]);

  const [showpassword, setShowPassword] = useState(false);
  
  const Refpwd = useRef()
  const Refnom = useRef()
  const Refprenom = useRef()
  const RefTel = useRef()
  const RefCodAg = useRef()
  const RefMatricule = useRef()
  const RefDr = useRef()
  const RefAgence = useRef()
  const RefFonction = useRef()
  // const agent = props.agent

  const listeModeles = (item) => {
    if (item.length > 0) {
      const Dr2 = item[0]['label']

      const data = agences.filter(
        row => (row.DR === Dr2))
      setAllAgence(data)
    } else {
      setAllAgence(agences)
    }
    // 






  }
  useEffect(() => {
    axiosInstance.get(`/DR`)
      
      .then((res) => {
        
        setAllDR(res.data)
      })
  }, [])

  useEffect(() => {
    axiosInstance.get(`/Fonction`)
    
      .then((res) => {
        
        setAllFonction(res.data)
      })
  }, [])
  
  useEffect(() => {
    setAllAgence(agences)
    

    if (agent.length !== 0) {

      axiosInstance.get(`/Tiers/${agent.Matricule}`)
      .then((res) => {
      setMatricule(agent.Matricule)  
      setAgence(res.data.Agence)
      
      setnom(res.data.Nom)
      setprenom(res.data.Prenom)

      setAgence(agences.filter(x => x.label === res.data.Agence))
      setDr(AllDR.filter(x => x.label === res.data.DR))
      setFonction(AllFonction.filter(x => x.label === res.data.Fonction))
      setrole(RoleOptions.filter(x => x.label === res.data.role))

      setPassword(res.data.pwd)
      setTel(res.data.Tel)

      Refnom.current.focus()
     } )

    } else {
      generatePassword()
      RefMatricule.current.focus()
    }
  }, [agent, AllDR, AllFonction])




  const valider = async(e) => {
  
    e.preventDefault()
    if (!Matricule) {
      setError('Veuillez entrer Matricule  !!')
      RefMatricule.current.focus()
    } else if (!nom) {
      setError('Veuillez entrer votre nom !!')
      Refnom.current.focus()

    } else if (!prenom) {
      setError('Veuillez entrer votre prénom !!')
      Refprenom.current.focus()

    } else if (Dr.length === 0) {
      setError('Veuillez entrer votre dr !!')
      RefDr.current.focus()


    } else if (Agence.length === 0) {
      setError('Veuillez entrer votre agence bancaire !!')
      RefAgence.current.focus()
    } else if (Fonction.length === 0) {
      setError('Veuillez entrer votre Fonction !!')
      RefFonction.current.focus()

    } else {

      const MySwal = withReactContent(Swal)
      MySwal.fire({
        title: 'Confirmation',
        text: `voulez-vous enregistrer?`,
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non'
      }).then((res) => {
        if (res.isConfirmed) {
          
          const Agent = {
            Matricule: parseInt(Matricule),
            pwd: password,
            Nom: nom,
            Prenom: prenom,
            Agence: Agence [0]['label'],
            Fonction: Fonction [0]['label'],
            DR: Dr [0]['label'],
            Tel

          }
          if(agent.length===0)
            {
             axiosInstance.post(`/Tiers`,Agent)
            
            .then((data) => {
              MySwal.fire(
                'Création avec succès   !',
                'Création .',
                'success'
              ).then((res) => {
                if (res.isConfirmed) {

                  window.location.reload()
                }
              })
            })
            .catch((error) => {
              const MySwal = withReactContent(Swal)

              MySwal.fire({
                title: 'error?',
                text: ` Error de Serveur `,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ok'
              })


            });
          }else{
            axiosInstance.put(`/Tiers/${Matricule}`,Agent)
      
              .then((data) => {
                MySwal.fire(
                  'Modification avec succès   !',
                  'Modification .',
                  'success'
                ).then((res) => {
                  if (res.isConfirmed) {
  
                    window.location.reload()
                  }
                })
              })
              .catch((error) => {
                const MySwal = withReactContent(Swal)
  
                MySwal.fire({
                  title: 'error?',
                  text: ` Error de Serveur `,
                  icon: 'error',
                  showCancelButton: false,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'ok'
                })
  
  
              });
          }
        
        }
      })

    }
  }
  const handleOnChange = (value, country) => {
    // value is the phone number input
    // country is the selected country data
    setTel(value);
    setupdate(false); 
    setError('')
  };
  const generatePassword = () => {
    const length = 10; // Longueur du mot de passe
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"; // Caractères possibles pour le mot de passe
    let newPassword = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      newPassword += charset[randomIndex];
    }
    setPassword('123456');
  };

  return (
    <>


      <div >
        {/* <h3 >Information Compte : </h3> */}
        {
          error && <div className='py-2 alert alert-danger' role='alert'>{error}</div>
        }
        <Row className="my-2">
          <Col md={6}>
            <Form.Group >
              <Form.Label>Matricule</Form.Label>
              {
                agent.length === 0 ? (
                  <Form.Control
                    required

                    ref={RefMatricule}

                    value={Matricule}
                    onChange={(e) => { setMatricule(e.target.value); setupdate(false); setError('') }}
                    onKeyDown={(e) => {

                      if (e.keyCode === 13) {
                        Refpwd.current.focus()
                      }
                    }}
                    name="prenom"
                    className="inputStyle input-color border border-dark"
                    type="number"
                    placeholder="Entrer Matricule"
                  />

                ) : (
                  <Form.Control
                    required
                    disabled
                    // {agent.Matricule!='' ? true : false}
                    //    ref={Refnom}
                    value={Matricule}
                    onChange={(e) => { setMatricule(e.target.value); setupdate(false); setError('') }}
                    onKeyDown={(e) => {

                      if (e.keyCode === 13) {
                        Refnom.current.focus()
                      }
                    }}
                    name="prenom"
                    className="inputStyle input-color border border-dark"
                    type="number"
                    placeholder="Entrer Matricule"
                  />

                )

              }
            </Form.Group>
          </Col>
          <Col md={6} >
            <Form.Group >
              <Form.Label>Mot de passe</Form.Label>
              {agent.length === 0 ? (
                <InputGroup>
                  <Form.Control
                    required
                    ref={Refpwd}
                    value={password}
                    onChange={(e) => {setPassword(e.target.value); setupdate(false); setError('')}}
                    onKeyDown={(e) => {

                      if (e.keyCode === 13) {
                        Refnom.current.focus()
                      }
                    }}
                    name="prenom"
                    className="inputStyle input-color border border-dark"
                    type="text"
                    placeholder="Entrer votre mot de passe"
                  />
                  {/* <Button className="btn btn-primary"><span className="glyphicon glyphicon-refresh"></span> Refresh</Button> */}
                  <Button
                    onClick={() => generatePassword()}

                    // className="visiblepass"
                    variant="outline-secondary"
                  >
                    <FontAwesomeIcon className='px-2' icon={faRefresh} />
                    {/* {showpassword ? (
                    <VisibilityIcon className="iconPassword" />
                  ) : (
                    <VisibilityOffIcon className="iconPassword" />
                  )} */}
                  </Button>
                </InputGroup>) : (
                <InputGroup>
                  <Form.Control
                    required
                    disabled
                    ref={Refpwd}
                    value={password}
                    onChange={(e) => {setPassword(e.target.value); setupdate(false); setError('')}}
                    onKeyDown={(e) => {

                      if (e.keyCode === 13) {
                        RefCodAg.current.focus()
                      }
                    }}
                    name="prenom"
                    className="inputStyle input-color border border-dark"
                    type={showpassword ? "text" : "password"}
                    placeholder="Entrer votre mot de passe"
                  />

                  <Button
                    onClick={() => setShowPassword(!showpassword)}

                    className="visiblepass"
                    variant="outline-secondary"
                  >

                    {showpassword ? (
                      <VisibilityIcon className="iconPassword" />
                    ) : (
                      <VisibilityOffIcon className="iconPassword" />
                    )}
                  </Button>
                </InputGroup>
              )
              }
            </Form.Group>
          </Col>



        </Row>
        <Row className="my-2">
          <Col md={6}>
            <Form.Group >
              <Form.Label>Nom</Form.Label>
              <Form.Control
                required
                ref={Refnom}
                value={nom}
                onChange={(e) => { setnom(e.target.value); setupdate(false); setError('') }}
                onKeyDown={(e) => {

                  if (e.keyCode === 13) {
                    Refprenom.current.focus()
                  }
                }}
                name="prenom"
                className="inputStyle input-color border border-dark"
                type="text"
                placeholder="Entrer Nom"
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group >
              <Form.Label>Prénom </Form.Label>
              <Form.Control
                required
                ref={Refprenom}
                value={prenom}
                onChange={(e) => { setprenom(e.target.value); setupdate(false); setError('') }}
                onKeyDown={(e) => {

                  if (e.keyCode === 13) {
                    RefTel.current.focus()
                  }
                }}
                name="prenom"
                className="inputStyle input-color border border-dark"
                type="text"
                placeholder="Entrer Prénom"
              />

            </Form.Group>
          </Col>

        </Row>
        <Row >


          <Col md={6} >
            <Form.Group >
              <Form.Label>Téléphone</Form.Label>
              <PhoneInput
                className="py-2"
                style={{ width: "80%", }}
                required
                ref={RefTel}
                defaultCountry="TN"
                placeholder="Entrer numéro de Téléphone"
                value={Tel}
                onKeyDown={(e) => {

                  if (e.keyCode === 13) {
                    RefDr.current.focus()
                  }
                }}
                onChange={handleOnChange}
                // onChange={()=>{setTel(); setupdate(false); setError('')}} 
                />

            </Form.Group>
          </Col>



        </Row>

        <Row className="my-2">
          <Col md={6}>
            <Form.Group >
              <Form.Label>DR</Form.Label>
              <Typeahead

                ref={RefDr}
                defaultValue={Dr}
                onChange={(item) => { setDr(item); setupdate(false); listeModeles(item); setError('') }}
                options={AllDR}
                placeholder="----"
                selected={Dr}
                onKeyDown={(e) => {

                  if (e.keyCode === 13) {
                    RefAgence.current.focus()
                  }
                }}
              />

            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group >
              <Form.Label> Agence</Form.Label>
              <Typeahead
                ref={RefAgence}
                defaultValue={Agence}
                onChange={(item) => { setAgence(item); setupdate(false); setError('') }}
                options={AllAgence}
                placeholder="----"
                selected={Agence}
                onKeyDown={(e) => {

                  if (e.keyCode === 13) {
                    RefFonction.current.focus()
                  }
                }}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group >
              <Form.Label>Fonction</Form.Label>
              <Typeahead
                ref={RefFonction}
                defaultValue={Fonction}
                onChange={(item) => { setFonction(item); setupdate(false); setError('') }}
                options={AllFonction}
                placeholder="----"
                selected={Fonction}
              />

            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group >
              <Form.Label>role</Form.Label>
              <Typeahead
                // ref={RefFonction}
                defaultValue={role}
                onChange={(item) => { setrole(item); setupdate(false); setError('') }}
                options={RoleOptions}
                placeholder="----"
                selected={role}
              />

            </Form.Group>
          </Col>
        </Row>


        <Row>
          <Col></Col>
          <Col align='right' >
            <button className='btn btn-primary me-3' size="sm"
              type="submit" block
              disabled={update}
              onClick={valider}
            >

              Enregistrer
            </button>

            



            <button type="cancel" className='btn btn-danger' size="sm"
              onClick={() => onPress()}
            >
             Fermer
            </button>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AddAgent;



