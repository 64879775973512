import React from "react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { VilleOptions, ClientOptions } from '../data.js'

import { Typeahead } from 'react-bootstrap-typeahead'
import Sidebar from './Sidebar';
import {
  clearCart,
  getTotals,
  removeFromCart,
} from "../slices/cartSlice";

import { Col, Row, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom';
import Header from "./Header";

import axiosInstance from "./api.jsx";

const Cart = (props) => {
  const user = localStorage.getItem('user');
  const id = localStorage.getItem('session');
  const role = localStorage.getItem('Type');
  

  const history = useHistory();



  const cart = useSelector((state) => state.cart);


  const [nature, setnature] = useState([])
  const [Titre, setTitre] = useState('C.I.N');
  const [error, setError] = useState("")
 
  const [nom, setnom] = useState("");
  const [ville, setVille] = useState([]);
  const [adresse, setadresse] = useState("");
  const [cin, setcin] = useState("");
  
  const [Tel, setTel] = useState("");
  // const [email, setEmail] = useState("");
  const [Nf, setNf] = useState('')
  const dispatch = useDispatch();

  const Refnom = useRef()
  const Refcin = useRef()

  const RefTel = useRef()
  const RefAdr = useRef()





  const changernature = (item) => {
    if (item.length > 0) {
      const nat = item[0]['label']
      setTitre(nat === 'Particulier' ? 'C.I.N' : 'MF')
    }

  }



  useEffect(() => {
    dispatch(getTotals());
  }, [cart, dispatch]);


  const handleRemoveFromCart = (product) => {
    dispatch(removeFromCart(product));
    history.push('/');
  };
  const handleClearCart = () => {
    dispatch(clearCart());
    history.push('/');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const order = {
      products: cart,
      agent: user,

      nom: nom,
      cin: cin,
      adresse: adresse,
      // Email: Email,
      Tel: Tel,
      // agence: user.user.Agence,
      ville: ville[0]['label'],
      nature: nature[0]['label'],
      Matricule: id
    }
    const MySwal = withReactContent(Swal)
    MySwal.fire({
      title: 'Confirmation',
      text: `voulez-vous passer votre commande ?`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non'
    }).then((res) => {
      if (res.isConfirmed) {
        axiosInstance.post(`/Ligcmd/Create`, order)

          .then((data) => {
            MySwal.fire(
              'Création avec succès   !',
              'Création .',
              'success'
            ).then((res) => {
              if (res.isConfirmed) {
                handleClearCart();
                if (role === "user") {
                  history.push('/commande')
                } else {
                  history.push('/')
                }

              }
            })
          })
          .catch((error) => {
            const MySwal = withReactContent(Swal)

            MySwal.fire({
              title: 'error?',
              text: ` Error de Serveur `,
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'ok'
            })


          });

      }
    })



  }


  const [openSidebarToggle, setOpenSidebarToggle] = useState(false)

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle)
  }
  return (
    <div className={role === 'admin' ? 'grid-container' : ''}>
      <Header OpenSidebar={OpenSidebar} />
      {role === 'admin' &&
        <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />

      }


      <main className='main-container'>

        <div className="container">
          <Row className='text-center py-2' >

            <h2 style={{ textDecoration: 'underline' }}>Détail Bon de commande </h2>
          </Row>

          {cart.cartItems.length === 0 && Nf === '' ? (
            <div className="cart-empty">
              <div className='py-2 alert alert-danger' role='alert'>Votre Panier est vide</div>

              <div className="start-shopping">
                <Link to="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-arrow-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                    />
                  </svg>
                  <span>Retour</span>
                </Link>
              </div>
            </div>
          ) : (
            <div>
              <div className="cart-item">
                <h5 className="product-title">Produit</h5>
                <h5 className="total">Couleur</h5>
                <h5 className="price">Prix.TTC</h5>
                {/* <h5 className="quantity">Quantité</h5> */}

              </div>
              <div className="cart-items">
                {cart.cartItems &&
                  cart.cartItems.map((cartItem) => (
                    <div className="cart-item" key={cartItem.IDArt}>
                      <div className="cart-product">
                        <img src={cartItem.ExLibArt} alt={cartItem.name} />
                        <div>
                          <h6>{cartItem.LibArt}</h6>
                          <p>{cartItem.desc}</p>
                          <button className="text-danger" onClick={() => handleRemoveFromCart(cartItem)}>
                            Supprimer
                          </button>
                        </div>
                      </div>
                      <div> {cartItem.Collection}</div>
                      <div className="cart-product-price">{cartItem.PrixTTC.toFixed(3)}</div>


                    </div>
                  ))}
              </div>
              <div className="cart-summary">
                <button className="clear-btn" onClick={() => handleClearCart()}>
                  Vider Panier
                </button>
                <div className="cart-checkout">

                  {/* <div className="continue-shopping">
                  <Link to="/Home">
                  <svg
                       xmlns="http://www.w3.org/2000/svg"
                       width="20"
                       height="20"
                       fill="currentColor"
                       className="bi bi-arrow-left"
                       viewBox="0 0 16 16"
                     >
                       <path
                         fillRule="evenodd"
                         d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                       />
                     </svg>
                     <span>Continuer</span>
                   </Link>
                 </div> */}
                </div>
              </div>
              <div className="border mt-2"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "50vh",



                }}
              >
                <div
                  className="client"
                  style={{

                    width: "80%",
                    height: "95%",
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                    margin: "2%",
                    marginTop: "3%",
                  }}
                >
                  <h3 >Information Client </h3>


                  {
                    error && <div className='py-2 alert alert-danger' role='alert'>{error}</div>
                  }
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md={6}>
                        <Form.Group >
                          <Form.Label>Raison sociale<span className="text-danger fw-bold">*</span></Form.Label>
                          <Form.Control
                            required
                            ref={Refnom}
                            value={nom}
                            onChange={(e) => setnom(e.target.value)}
                            onKeyDown={(e) => {

                              if (e.keyCode === 13) {
                                RefTel.current.focus()
                              }
                            }}
                            name="prenom"
                            className="inputStyle input-color border border-dark"
                            type="text"
                            placeholder="Entrer Client ..."
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group >
                          <Form.Label>Téléphone<span className="text-danger fw-bold">*</span></Form.Label>
                          <PhoneInput
                            required
                            ref={RefTel}
                            defaultCountry="TN"
                            placeholder="Entrer numéro de Téléphone ..."
                            value={Tel}
                            onKeyDown={(e) => {

                              if (e.keyCode === 13) {
                                // RefEmail.current.focus()
                              }
                            }}
                            onChange={setTel} />

                        </Form.Group>
                      </Col>
                    </Row>




                    <Row className="my-2">
                      <Col md={6} className="mb-3">
                        <Form.Group id="lastName">
                          <Form.Label>Nature Client<span className="text-danger fw-bold">*</span></Form.Label>
                          <Typeahead
                            defaultValue={nature}
                            onChange={(item) => { setnature(item); changernature(item) }}
                            options={ClientOptions}
                            placeholder="sélectionner nature du client ..."
                            selected={nature}
                            clearButton
                          />


                        </Form.Group>
                      </Col>


                      <Col md={6}>
                        <Form.Group >
                          <Form.Label>{Titre}<span className="text-danger fw-bold">*</span></Form.Label>
                          <Form.Control
                            required
                            type="tel"

                            value={cin}
                            ref={Refcin}
                            onChange={(e) => setcin(e.target.value)}
                            onKeyDown={(e) => {

                              if (e.keyCode === 13) {
                                //  RefEmail.current.focus()
                              }
                            }}

                            className="inputStyle2 input-color border border-dark"

                            placeholder={'Entrer ' + Titre + ' ...'}
                          />
                        </Form.Group>
                      </Col>


                    </Row>







                    <Row className="my-2">


                    </Row>
                    <Row className="my-2">
                      <Col md={6}>
                        <Form.Group >
                          <Form.Label>Ville<span className="text-danger fw-bold">*</span></Form.Label>
                          <Typeahead
                            defaultValue={ville}
                            onChange={(item) => { setVille(item) }}
                            options={VilleOptions}
                            placeholder="select ville ..."
                            selected={ville}
                            clearButton
                          />

                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group >
                          <Form.Label>Adresse<span className="text-danger fw-bold">*</span></Form.Label>
                          <Form.Control
                            required
                            ref={RefAdr}
                            onChange={(e) => setadresse(e.target.value)}

                            value={adresse}

                            className="inputStyle3 input-color border border-dark"
                            type="text"
                            placeholder="Entrer Adresse ..."
                          />
                        </Form.Group>
                      </Col>
                    </Row>


                    <Button className={window.innerWidth > 1000 ? "envoyerT" : "envoyerT2"} variant="primary" type="submit">  Confirmer commande
                    </Button>







                  </Form>
                </div>

              </div>

            </div>
          )}



        </div>
      </main>
    </div>








  );
};

export default Cart;
