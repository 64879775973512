import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/js/dist/dropdown'

import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";

import productsReducer , { productsFetch } from "./slices/productsSlice"; 
import userReducer from "./slices/userSlice";
import agenceReducer, { agencesFetch } from "./slices/agenceSlice";
import agentReducer, { agentsFetch } from "./slices/AgentSlice";
import cartReducer, { getTotals } from "./slices/cartSlice";
import { productsApi } from "./slices/productsApi";
document.body.style.zoom =0.9
const store = configureStore({
  reducer: {
    user : userReducer,
    products: productsReducer,
    cart: cartReducer,
    agences : agenceReducer,
    agents : agentReducer
    // [productsApi.reducerPath]: productsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(productsApi.middleware),
});


store.dispatch(getTotals());


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
